import React from 'react'
import { Container, CardJunction } from './ControlContainer.style'
import cImage1 from './Images/c-images-1.png'
import cImage2 from './Images/c-images-2.png'
import cImage3 from './Images/c-images-3.png'
import ControledCard from '../ControlCard/Controled.component'
export default function ControlContainer() {
    return (
        <Container>
            <h1>Take Control of Your Future</h1>
            <CardJunction>
                <ControledCard Cimage={cImage1} paragraph={"First you'll enroll in a top notch training program in order to get you comfortable talking about HouseChat.com."} />
                <ControledCard Cimage={cImage2} paragraph={"After graduation, you'll have the tools you need to start implementing local marketing strategies, generating leads, and conducting appointments on your own."} />
                <ControledCard Cimage={cImage3} paragraph={"You'll have a sharp community focus with your pulse on the local real estate market. Making connections and building lasting relationships."} />
            </CardJunction>
        </Container>
    )
}
