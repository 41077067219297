/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin: 4rem auto;
  .ImageContainer{
    img{
        width: 100%;
    }
  }
`;

export const Content = styled.div`
   margin-bottom: 2rem;
  text-align: center;
  padding: 0 4rem;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
    margin-bottom: 2rem;
  }
  p {
    color: #474747;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(0.75rem , 1vw,1rem);
    font-weight: 400;
    width: min(85%,55rem);
    margin: 0 auto;
  }
  @media screen and (max-width: 675px) {
    padding: 0 1rem;
    /* h1 {
      font-size: 1.7rem;
    } */
  }
  @media screen and (max-width: 458px) {
    /* h1,p{
        text-align: left;
    } */
  }
`;
