import ReactPaginate from "react-paginate";

const Paginate = ({ forcePage,onPageChange, pageCount, pageRangeDisplayed = 5 }) => {
  return (
    <ReactPaginate
      breakLabel="..."
      forcePage={forcePage - 1}
      nextLabel="Next >"
      onPageChange={onPageChange}
      pageRangeDisplayed={pageRangeDisplayed}
      pageCount={pageCount}
      previousLabel="< Previous"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination d-flex justify-content-center flex-wrap"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};

export default Paginate;
