import React from 'react'
import { Container } from './Controled.style'
export default function ControledCard({ Cimage, paragraph }) {
    return (
        <Container>
            <div className="imgContainer">
                <img src={Cimage} alt='cimage' />
            </div>
            <div className="content">
                <p>{paragraph}</p>
            </div>

        </Container>
    )
}
