import React, { memo } from "react";
import Bg1Img from "../images/stepper-bg-1.png";

const FirstValuationForm = ({
  goNext,
  formFields,
  updateFormField,
}) => {
  const handleSubmit = (e) => {
    goNext();
  };
  return (
    <div className="step-layout">
      <section
        className="step-layout-left "
        style={{ background: `url(${Bg1Img}) rgba(0, 0, 0, 0.7)` }}
      >
        <div className="step-layout-container">
          <h2 className="text-white intro-title">
            Real estate board require that we
            <br className="d-none d-sm-inline" />
            verify your information to get full access to our sold listings data
            base.
          </h2>
        </div>
      </section>
      <section className="step-layout-right">
        <div className="step-layout-container">
          <h2 className="text-primary title">
            We require your information to provide accurate valuations!
          </h2>
          <form className="text-center" onSubmit={handleSubmit}>
            <div className="form-group input-group-lg text-left">
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                required
                defaultValue={formFields?.name}
                onChange={(e) => updateFormField("name", e.target.value)}
              />
            </div>
            <div className="form-group input-group-lg text-left">
              <input
                type="email"
                className="form-control"
                placeholder="E-mail"
                required
                defaultValue={formFields?.email}
                onChange={(e) => updateFormField("email", e.target.value)}
              />
            </div>
            <div className="form-group input-group-lg text-left">
              <input
                type="text"
                className="form-control"
                placeholder="Phone"
                required
                defaultValue={formFields?.phone}
                onChange={(e) => updateFormField("phone", e.target.value)}
              />
            </div>
            <div className="text-left text-lg-end submit-btn-wrapper">
              <button className="btn btn-primary btn-lg submit-btn">
                Continue
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default memo(FirstValuationForm);
