import React, { useState } from 'react'
import Add from './images/add.png'
import Subtract from './images/subtract.png'
import { Container, Content } from './FaqCard.style'
export default function FaqCard({ heading, paragraph }) {
    const [open, setOpen] = useState(false)
    return (
        <Container>
            <Content>
                <h1 className='heading'>{heading}</h1>
                <img src={open ? Subtract : Add} alt='add/subtract' onClick={() => setOpen(!open)} />
            </Content>
            {open &&
                <div className='Ans'>
                    <p>{paragraph}</p>
                </div>
            }

        </Container>
    )
}
