const imgBaseUrl = "https://s3.us-west-1.wasabisys.com/redbuyers-main/";

export const priceFormat = (price) => {
  const roundOff = (Math.round(price * 100) / 100)?.toFixed();
  const priceWithCommas = roundOff
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return priceWithCommas;
};

export const getPropertyAddress = (propertyDetails) => {
  return (
    propertyDetails?.["address"] +
    ", " +
    propertyDetails?.["city"] +
    ", " +
    propertyDetails?.["area"] +
    ", " +
    propertyDetails?.["zip_code"] +
    ", " +
    propertyDetails?.["county"]
  );
};

export const getFirstPhoto = (propertyDetails) => {
  const firstPhoto = propertyDetails?.photos?.[0];
  if (firstPhoto && typeof firstPhoto === "object") {
    return imgBaseUrl + firstPhoto.url;
  } else if (typeof firstPhoto === "string") {
    return firstPhoto;
  }
};

export const getGarage = (propertyDetails, type = null) => {
  if (type === "SOLD_PROPERTY") {
    return propertyDetails?.property_details?.Gar_spaces;
  } else {
    if (
      propertyDetails?.["garage_spaces"] === "undefined" ||
      propertyDetails?.["garage_spaces"] === undefined
    ) {
      return propertyDetails?.["garage"] || 0;
    } else {
      return propertyDetails?.["garage_spaces"] || 0;
    }
  }
};

export const dayDiff = (start, end) => {
  if (!start || !end) return;
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return days;
};
