/** @format */

import React from 'react';
import { Container } from './NonTraditionalWay.style';
import Cards from './Cards.component';
import Image1 from './Images/Card-T-1.jpg';
import Image2 from './Images/Card-T-2.jpg';
import Image3 from './Images/Card-T-3.png';
import { AiFillDollarCircle } from 'react-icons/ai';
import { GoDotFill } from 'react-icons/go';
import { BiSolidOffer } from 'react-icons/bi';
import { BsFillCameraVideoFill } from 'react-icons/bs';
import { GiArchiveRegister } from 'react-icons/gi';
import { MdVerified } from 'react-icons/md';
export default function NonTraditionalWay() {
    const firstList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Get a cash offer in a few short steps in 24 hours.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Show the house or do a video showing and get final offer.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Negotiate and finalize the offer with clarity on all terms, and options.
                </p>
            </div>

            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Accept our cash offer and mark your house sold by getting paid at closing
                </p>
            </div>
            <button>Learn More</button>
        </>
    );
    const SecondList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Register and login. List your home in few steps.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    We have automated everything from showings to offers.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    You will have an associate to help you with every step.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Photography, videos, and staging are extra cost but optional.
                </p>
            </div>
        </>
    );
    const ThirdtList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    You will be taken care by and affiliated realtor and help you every step
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Your house is open to public for showing in a traditional way.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    We will be always available to help you through out the process.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    Realtor will help you with selling and offer processing.
                </p>
            </div>
        </>
    );
    return (
        <Container>
            {/* <img src="https://www.redbuyers.com/assets/home_page/img/line.png" alt='mage-dash' /> */}
            <h1 className='Heading'>WAYS TO SELL</h1>
            <p>Selling your home. A non-traditional way.</p>
            <div className="content">
                <Cards
                    no={'1'}
                    list={firstList}
                    heading={
                        <h1>
                            Sell to us <br /> (iBuying) Get our cash offer in 24 HOURS
                        </h1>
                    }
                    ending={
                        'If our cash offer is not acceptable to sellers, then explore our other options.'
                    }
                    Image={Image1}
                    reverse={'uppersection imagearea'}
                    imageStyle={'7 / span 6'}
                    contentStyle={'1 /span 5'}
                />
                <Cards
                    no={'2'}
                    list={SecondList}
                    heading={<h1>Exclusive listing <br /> Try for higher price.</h1>}
                    ending={
                        'You can always apply for cash offer if your house is not sold.'
                    }
                    Image={Image2}
                    reverse={'imagearea uppersection'}
                    contentStyle={'8 / span 5'}
                    imageStyle={'span 6'}
                />
                <Cards
                    no={'3'}
                    list={ThirdtList}
                    heading={
                        <h1>MLS listing <br /> Through our affiliated  brokerages (optional)</h1>
                    }
                    ending={
                        'You can always apply for cash offer if your house not sold.'
                    }
                    Image={Image3}
                    reverse={'uppersection imagearea'}
                    imageStyle={'7 / span 6'}
                    contentStyle={'1 /span 5'}
                />
            </div>
        </Container>
    );
}
