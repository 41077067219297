/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem 2rem;
  text-align: center;
    @media screen and (max-width: 915px) {
    height: 100%;
  }
`;
export const Heading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  p {
    color: #474747;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(0.75rem , 1vw,1rem);
    font-weight: 400;
    width: min(75%,30rem);
    margin-bottom: 4rem;
  }
    @media screen and (max-width: 915px) {
      h1{
        font-size: 2rem;
      }
    p{
      width: 100%;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  /* height: 100%; */
  gap: 1rem;
    @media screen and (max-width: 915px) {
    flex-direction: column;
    height: 100vh;
  }
     @media screen and (max-width: 1070px) {
      gap:2rem;
   }
`;
