/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  /* width:; */
  text-align: left;
  background-color: white;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: grid;
  grid-template-rows: auto 1fr;
  .content {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
  }
  .imgContainer {
    img {
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    margin-top: 1rem;
    color: #000;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #474747;
    font-family: DM Sans;
    font-size:clamp(0.75rem , 1vw,1rem);
    font-weight: 400;
  }
  button {
    border-radius: 0.9375rem;
    background: #261c1d;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    color: white;
    width: 100%;
    padding: 1rem;
  }
`;
