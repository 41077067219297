/** @format */

import React from 'react';
import { Container } from './BuyOfMarketHomes.style';
import Cards from './Cards.component';
import Image1 from './Images/Card-B-1.jpg';
import Image2 from './Images/Card-B-2.jpg';
import Image3 from './Images/Card-B-3.jpg';
import { MdVerified } from 'react-icons/md';
export default function BuyOfMarketHomes() {
    const firstList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>
                    {' '}
                    From MLS, through our affiliated realtors, you can negotiate and buy.
                </p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> From our off-market properties, you can buy directly from us.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> From our iBuying homes, we match 10% down payment program.</p>
            </div>
        </>
    );
    const SecondList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> Co-Invest Buy 2-4 people/families together to buy a single home,</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> We created a system to accommodate co-buying to co-living.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> We take care of renovation to accommodate your privacy.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> You have right to cancel anytime and move on buy something else.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> We convert single home to duplex, triplex & fourplex.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> With easy down $25K, $50K, & $100K down payment.</p>
            </div>
        </>
    );
    const ThirdtList = (
        <>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> Fractionation Buying through affiliated blockchain platform.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> Invest in rental properties without getting locked in (or out).</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p> $5K , $10K fractional share with 5-7% guaranteed rental return.</p>
            </div>
            <div className="list">
                <MdVerified
                    size={22}
                    color="#261c1d"
                />
                <p>   Sell and get your share value appreciation anytime during the period.</p>
            </div>
        </>
    );
    return (
        <Container>
            <h1 className='Heading'>Ways to BUY / Invest</h1>
            <p>
                Buy off-market homes you can't find{' '}
                <span style={{ fontStyle: 'italic', color: 'gray', fontSize: '18px' }}>
                    anywhere else.
                </span>
            </p>
            <div className="content">
                <Cards
                    no={'1'}
                    list={firstList}
                    heading={'Traditional buying'}
                    Image={Image1}
                    reverse={'uppersection imagearea'}
                    imageStyle={'7 / span 6'}
                    contentStyle={'1 /span 5'}
                />
                <Cards
                    no={'2'}
                    list={SecondList}
                    heading={'Co-Invest'}
                    // ending={
                    //     'You can always apply for cash offer if your house is not sold.'
                    // }
                    Image={Image2}
                    reverse={'imagearea uppersection'}
                    contentStyle={'8 / span 5'}
                    imageStyle={'span 6'}
                />
                <Cards
                    no={'3'}
                    list={ThirdtList}
                    heading={'Investors Option: Fractional real estate Marketplace.'}
                    // ending={
                    //     'You can always apply for cash offer if your house not sold.'
                    // }
                    Image={Image3}
                    reverse={'uppersection imagearea'}
                    imageStyle={'7 / span 6'}
                    contentStyle={'1 /span 5'}
                />
            </div>
        </Container>
    );
}
