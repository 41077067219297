/** @format */

import styled from 'styled-components';

export const Conatainer = styled.div`
  margin: 3rem 0;
  padding: 2rem 9rem;
  
  @media screen and (max-width: 1124px) {
    padding: 2rem;
  }
  @media screen and (max-width: 458px) {
    padding: 0;
  }
`;
export const Content = styled.div`
  text-align: center;
  padding: 2rem 3rem;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
    margin-bottom: 1rem;
  }
  p {
    color: #474747;
    font-family: DM Sans;
    font-size:clamp(0.75rem , 1vw,1rem);
    font-weight: 400;
    width: min(32rem,95%);
    margin: 0 auto;
  }
  @media screen and (max-width: 1170px) {
    padding: 2rem 10rem;
  }

  @media screen and (max-width: 847px) {
    padding: 2rem;
  }
  @media screen and (max-width: 458px) {
    /* h1 {
      font-size: 1.5rem;
    } */
  }
`;
export const CardJunction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 847px) {
    flex-direction: column;
  }
`;
