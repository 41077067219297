import React from 'react';
import { Conatainer, Content } from './BuyAndSellHome.style';
import HeroImage from './Images/way-to-sell.jpg'
export default function BuyAndSellHero() {
    return (
        // <Conatainer>
        //     <Content>
        //         <h1>Buy / Invest and Sell. <br />
        //             A non-traditional approach.</h1>
        //         <div className="nevigation">
        //             <button>Ways to Sell</button>
        //             <button>Ways to Buy/Invest.</button>
        //         </div>
        //     </Content>
        // </Conatainer>

        <Conatainer>
            <div className="main">
                <Content>
                    <h1>Buy / Invest and Sell. <br />
                        A non-traditional approach.</h1>
                    <div className="nevigation">
                        <button>Ways to Sell</button>
                        <button>Ways to Buy/Invest.</button>
                    </div>
                </Content>
                <div className='ImageContainer'>
                    <img src={HeroImage} alt="HeroImage" />
                </div>
            </div>
        </Conatainer>
    );
}
