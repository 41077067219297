/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem 2rem;
  text-align: center;
  width: min(95%,100rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto ;
  h1 {
    color: #000;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
  }
    @media screen and (max-width: 458px) {
    /* h1{
      font-size: 1.5rem;
    } */
  }
`;
export const CardJunction = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 4rem;
  @media screen and (max-width: 913px) {
      grid-template-columns:65%;
      justify-content: center;
  }
    @media screen and (max-width:644px) {
      grid-template-columns:1fr;
  }
`;
