import React from 'react'
import { Container, UpperSection } from './Cards.style'
export default function Cards({ no, list, heading, ending, Image, reverse, contentStyle, imageStyle }) {
    return (
        <Container area={reverse} Imagestyle={imageStyle}>
            <UpperSection no={no} Contentstyle={contentStyle} >
                <h1>OPTION {no}</h1>
                <h1>{heading}</h1>
                <div className="Content">
                    {list}
                </div>
                <p className='ending'>{ending}</p>
            </UpperSection>
            <div className="imageContainer">
                <img src={Image} alt="cardImage" />
            </div>
        </Container>
    )
}
