/** @format */

import React from 'react';
import { Conatainer, Content } from './AssociateHeroSection.style';
import HeroImage from './Images/AssociateheroImage.jpg';
export default function AssociateHeroSection() {
    return (
        <Conatainer>
            <div className="main">
                <Content>
                    <h1>Work whenever you want, Make what you need with HouseChat.</h1>
                    <p>Maximize leads by networking.</p>
                    <p> Buyers | Sellers | Rental | New-constructions</p>
                    <button>Get Started</button>
                </Content>
                <div className="ImageContainer">
                    <img
                        src={HeroImage}
                        alt="HeroImage"
                    />
                </div>
            </div>
        </Conatainer>
    );
}
