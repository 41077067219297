import React from 'react'
import BenefitsHeroSection from '../../components/BenefitsHeroSection/BenefitsHeroSection.component'
import AllianceBussiness from '../../components/AllianceBussiness/AllianceBussiness.component'
import BuildingBuissness from '../../components/BuildingBuissness/BuildingBuissness.component'
import AllieanceTeamDiffreance from '../../components/AllianceTeamDiffreance/AllieanceTeamDiffreance.component'
import PartTime from '../../components/PartTime/PartTime.component'
import BuildIncome from '../../components/BuildIncome/BuildIncome.component'
export default function AssociateBenefits() {
    return (
        <>
            <BenefitsHeroSection />
            <AllianceBussiness />
            <BuildingBuissness />
            <AllieanceTeamDiffreance />
            <PartTime />
            <BuildIncome />
        </>
    )
}
