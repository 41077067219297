import React from 'react'
import BuyAndSellHero from '../../components/BuyAndSellHome/BuyAndSellHome.component';
import NonTraditionalWay from '../../components/nonTraditionalWay/NonTraditionalWay.component';
import BuyOfMarketHomes from '../../components/BuyOfMarketHomes/BuyOfMarketHomes.component';
export default function BuyAndSell() {
    return (
        <>
            <BuyAndSellHero />
            <NonTraditionalWay />
            <BuyOfMarketHomes />
        </>
    )
}
