import React from 'react'
import DiffCard from './DiffCard.component'
import Img1 from './Images/DifImage-1.png'
import Img2 from './Images/Diffmage-2.png'
import { DiffConntainer, Content, Container } from './AlleanceTeamDiffreance.style'
export default function AllieanceTeamDiffreance() {
    return (
        <Container>
            <Content>
                <h1>The HouseChat Difference</h1>
                <p>The HouseChat business model allows ambitious entrepreneurs like you to build a financial services organization on your terms. Full time or part time. Day or night. Scale without limits.</p>
            </Content>
            <DiffConntainer>
                <DiffCard Heading={"Traditional Agency"} Image={Img1} />
                <DiffCard Heading={"Our Business Models"} Image={Img2} />
            </DiffConntainer>
        </Container>
    )
}
