import React from 'react'
import { Container } from './DiffCard.style'
export default function DiffCard({ Heading, Image }) {
    return (
        <Container>
            <h1>{Heading}</h1>
            <img src={Image} alt='diffImage' />
        </Container>
    )
}
