import React from 'react'
import { Container } from './PartTime.style'
import PartTimeImage from './Images/PartTime.jpg'
export default function PartTime() {
    return (
        <Container>
            <div className="main">
                <div className="content">
                    <h1>Start Part Time and Go Full Time <br /> When You’re Ready</h1>
                    <p>There’s no need to quit your current job to find out if HouseChat is right for you as an independent agent. Test the waters as a part-time insurance agent with with HouseChat and discover the flexibility that it offers to our financial professionals.</p>
                    <button>
                        Get started
                    </button>
                </div>
                <div className="imageContainr">
                    <img src={PartTimeImage} alt='Traditional' />
                </div>
            </div>
        </Container>
    )
}
