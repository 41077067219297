import React from 'react'
import { Container, CardJunction } from './Faq.style'
import FaqCard from './FaqCard.component'
export default function Faq() {
    return (
        <Container>
            <h1>FAQs</h1>
            <CardJunction>
                <FaqCard heading={"Why HouseChat?"} paragraph={"Sales associate opportunities can mean networking wherever you go with your own schedules. If you become a sales associate with HouseChat , there is opportunity everywhere you go."} />
                <FaqCard heading={"Why HouseChat?"} paragraph={"Sales associate opportunities can mean networking wherever you go with your own schedules. If you become a sales associate with HouseChat , there is opportunity everywhere you go."} />
                <FaqCard heading={"Why HouseChat?"} paragraph={"Sales associate opportunities can mean networking wherever you go with your own schedules. If you become a sales associate with HouseChat , there is opportunity everywhere you go."} />
                <FaqCard heading={"Why HouseChat?"} paragraph={"Sales associate opportunities can mean networking wherever you go with your own schedules. If you become a sales associate with HouseChat , there is opportunity everywhere you go."} />
            </CardJunction>
        </Container>
    )
}
