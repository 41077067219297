import React from 'react'
import BussinessImage from './Images/BussinessImage.jpg'
import { Container, Content } from './AllianceBussiness.style'
export default function AllianceBussiness() {
    return (
        <Container>
            <Content>
                <h1>HouseChat Business Model: Designed for Everyone</h1>
                <p>We help make the idea of home selling and buying accessible through a diverse group of people from a wide range of backgrounds. HouseChat offers training and support to access to those that are looking to create a full-time business or one that is part time to earn extra income. We believe technology can provide all kind of support to real estate sales, and not need to pay huge commissions to realtors to guide to buy or sell real estate. Our associates are not a license real estate agents or brokers, but trained consultant of all calibers, we are here to support the growth of your business.</p>
            </Content>
            <div className='ImageContainer'>
                <img src={BussinessImage} alt="BussinessImage" />
            </div>
        </Container>
    )
}
