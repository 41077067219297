import React, { useCallback, useRef, useState } from "react";
import FirstValuationForm from "./components/FirstValuationForm";
import SecondValuationForm from "./components/SecondValuationForm";
import FinalValuation from "./components/FinalValuation/FinalValuation";
import "./style.css";
import axios from "axios";

const FindHomeValuePage = () => {
  const [step, setStep] = useState(1);
  const [isValuationDisplay, setValuationDisplay] = useState(false);
  const [valuation, setValuation] = useState();
  const [isValuationLoading, setValuationLoading] = useState();
  const [formFields, setFormFields] = useState({
    utm_source: "RedBuyers",
    utm_medium: "redbuyers.com",
    utm_campaign: "IN_HOUSE",
    utm_id: "REDBUYERS",
    utm_content: "First Step",
    name: "",
    email: "",
    phone: "",
    type: "",
    bedrooms: "",
    bathrooms: "",
    square_foot: "",
    home_style: "",
    garage: "",
    basement: "",
    address: "16 Harthill Ct, Brampton, ON L7A 2N9, Canada",
    address_short: "",
    city: "",
    zip_code: "",
    latitude: "",
    longitude: "",
    offer_type: "cash",
  });
  const finalValuationSecRef = useRef();

  const updateFormField = useCallback(
    (name, value) => {
      console.log({ name, value });
      setFormFields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
    },
    [setFormFields]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setValuation(null);
    setValuationLoading(true);
    setValuationDisplay(true);
    setTimeout(() => {
      finalValuationSecRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
    const formData = new URLSearchParams();
    for (let [key, value] of Object.entries(formFields)) {
      formData.append(key, value);
    }
    axios
      .get(
        "https://valuation.santhoshmathew.com/api/v1/get-home-value-estimation?" +
          formData,
        formData
      )
      .then((response) => {
        console.log(response.data);
        setValuation(response.data.data);
        getIdxProperties();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally((result) => {
        setValuationLoading(false);
      });
  };

  const getIdxProperties = () => {
    const q = new URLSearchParams();
    for (let [key, value] of Object.entries(formFields)) {
      q.append(key, value);
    }
    axios
      .get(
        "https://valuation.santhoshmathew.com/api/v1/get-idx-home-in-home-valuation?" +
          q
      )
      .then((response) => {
        setValuation((prev) => ({
          ...prev,
          saleProperties: response?.data?.data,
        }));
      });
  };

  return (
    <div className="mb-5 pb-5">
      {step === 1 && (
        <FirstValuationForm
          formFields={formFields}
          updateFormField={updateFormField}
          goNext={() => {
            setStep(2);
            updateFormField("utm_content", "Seventh Step");
          }}
        ></FirstValuationForm>
      )}

      {step === 2 && (
        <SecondValuationForm
          formFields={formFields}
          updateFormField={updateFormField}
          goBack={() => {
            setStep(1);
            updateFormField("utm_content", "First Step");
          }}
          submit={handleSubmit}
        ></SecondValuationForm>
      )}

      {isValuationDisplay && (
        <FinalValuation
          formFields={formFields}
          valuation={valuation}
          isLoading={isValuationLoading}
          ref={finalValuationSecRef}
        />
      )}
    </div>
  );
};

export default FindHomeValuePage;
