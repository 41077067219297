import React from 'react'
import { Container } from './Reimaged.style'
import Image from './Images/Hero-Image.webp'
export default function Reimaged({ content, Image, reverse, contentStyle, imageStyle }) {
    return (
        <Container area={reverse} Contentstyle={contentStyle} Imagestyle={imageStyle} >
            <div className="main">
                <div className='Image'>
                    <img src={Image} alt="sideimage" />
                </div>
                {content}
            </div>
        </Container>
    )
}
