/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 7rem;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
  }
  
  @media screen and (max-width:717px) {
     padding: 1rem;
  }
   @media screen and (max-width: 475px) {
    h1{
      font-size: 1.8rem;
    }
  }
`;
export const CardJunction = styled.div`
  margin:2rem auto;
  width: 65%;

  @media screen and (max-width:1280px) {
     width: 75%;
  }
`;