import { lazy, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/NavBar/Navbar.component';
import Home from './pages/Home/Home.page';
import Associate from './pages/Associate/Associate.page';
import AssociateBenefits from './pages/AssociateBenefits/AssociateBenefits.page';
import BrowseHomes from './pages/BrowseHomes';
import BuyAndSell from './pages/BuyAndSell/BuyAndSell.page';import FindHomeValuePage from './pages/FindHomeValue/FindHomeValue.page';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="associate" element={<Associate />} />
          <Route path='benefits' element={<AssociateBenefits />} />
          <Route path='browse-homes' element={<BrowseHomes />} />
          <Route path='buy-sell' element={<BuyAndSell />} />
          <Route path='find-home-value' element={<FindHomeValuePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
