/** @format */

import styled from 'styled-components';

export const Container = styled.div`
 background: #fafafc;
.main{
      margin-top: 4rem;
  /* padding: 2rem 7rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(85rem,85%);
  margin: 0 auto;
  gap: 4rem;
  img {
    flex:1;
    width: 50%;
  }
  .content {
    flex: 1;
    p {
      color: #474747;
      font-family: DM Sans;
      font-size: 1rem;
      font-weight: 400;
    }
    h1 {
      color: #000;
      font-family: DM Sans;
      font-size: 3rem;
      font-weight: 400;
      white-space: nowrap;
    }
    @media screen and (max-width: 1078px) {
      width: 95%;
    }
  }
  @media screen and (max-width: 1515px) {
    padding: 2rem;
  }
  @media screen and (max-width: 1078px) {
    img {
      width: 75%;
    }
    flex-direction: column;
  }
  @media screen and (max-width: 915px) {
    padding: 1rem;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 1515px) {
    .content {
      h1 {
        font-size: 2rem;
      }
    }
  }
      @media screen and (max-width: 615px) {
      .content{
        h1 {
        margin-bottom: 1rem;
        font-size: 1.7rem;
      }
      }
    }
}
 
`;
