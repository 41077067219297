/** @format */

import React from 'react';
import { Container, UperSection, DownSection } from './BuildingBussiness.style';
export default function BuildingBuissness() {
    return (
        <Container>
            <UperSection>
                <div className="leftSection">
                    <p>
                        HouseChat business model offers a unique opportunity to help
                        make home ownership accessible to a variety of individuals from a
                        wide range of backgrounds. ‍ Every data is accessible through
                        internet, and with HouseChat education systems, our associate,
                        can guide people to buy or sell homes directly without paying huge
                        commissions. A proven system that can produce thousands of people
                        extra incomes with our successful technology based real estate
                        systems.
                    </p>
                    <button>Get Started</button>
                </div>
                <div className="rightSection">
                    <h3>Start Building Your Business</h3>
                    <h1>with HouseChat</h1>
                </div>
            </UperSection>
            <DownSection>
                <div className="leftSection">
                    <h1>Become a HouseChat <br /> Associate</h1>
                </div>
                <div className="rightSection">
                    <p>
                        HouseChat associate have access to our real estate training
                        resources that can help them become associate to help guide people
                        buy and sell through our line of products. Up-and-coming associate
                        can also receive mentoring and support from the HouseChat to
                        become licensed realtors if they wish. HouseChat also offers
                        high end technology support, business-building guidance, and
                        marketing resources to help you promote, monitor, and grow your
                        business.
                    </p>
                </div>
            </DownSection>
        </Container>
    );
}
