import React, { forwardRef } from "react";
import styled from "styled-components";
import bannerImg from "./images/banner_home.png";
import so1Img from "./images/so1.png";
import so2Img from "./images/so2.png";
import moneyImg from "./images/money.png";
import likeImg from "./images/like_new.png";
import tickImg from "./images/tick_new.png";
import locationImg from "./images/location_new.png";
import spinnerGif from "./images/spinner.gif";
import "./style.css";
import { priceFormat } from "../../../../utils/property";
import ValuationOfferTabs from "../ValuationOfferTabs";

const Banner = styled.div`
  &:before {
    background: url(${bannerImg});
    content: "";
    background-repeat: no-repeat !important;
    background-size: 100% 100%;
    background-position: bottom;
    position: absolute;
    height: 380px;
    width: 1163px;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;
const Benefits = styled.ul`
  list-style: none;
  li:before {
    background: url(${likeImg});
  }
`;
const NextSteps = styled.ul`
  list-style: none;
  li:before {
    background: url(${tickImg});
  }
`;

const FinalValuation = forwardRef(
  ({ formFields, valuation, isLoading }, ref) => {
    const renderBannerContent = () => {
      if (isLoading) {
        return (
          <div className="new_banner_content_box mt-5">
            <div className="mb-4 mb-md-5">
              <img src={spinnerGif} width="42" alt="Spinner" />
              <span className="pl-2 font-weight-bold" style={{ fontSize: 20 }}>
                Please Wait! Calculating offer.
              </span>
            </div>
          </div>
        );
      } else if (valuation) {
        return (
          <div className="new_banner_content_box">
            <div className="new_banner_content_title">
              <h1>Your Home Value</h1>
              <p>Based on Automated Valuation Method (AVM)</p>
              <p>
                <span>
                  <img src={locationImg} className="img-fluid" alt="location" />
                </span>
                {formFields?.address}
              </p>
            </div>
            <div className="new_banner_content_price">
              <h2>${priceFormat(valuation?.average)}</h2>
              <small>
                Approximate value based on similar homes in the area.
              </small>
            </div>
            <div className="new_banner_content_slide">
              <ul className="slider_title list-unstyled">
                <li>LOW</li>
                <li>AVERAGE</li>
                <li>HIGH</li>
              </ul>
              <div className="slider_bar">
                <div className="slider_bar_ball"></div>
              </div>
              <ul className="slider_price list-unstyled">
                <li>${priceFormat(valuation?.min)}</li>
                <li>${priceFormat(valuation?.average)}</li>
                <li>${priceFormat(valuation?.max)}</li>
              </ul>
              <small>
                *We can provide an accurate home value, after viewing the
                property
              </small>
            </div>
            <div className="new_banner_content_button">
              <a href="https://www.redbuyers.com/presentation-get-cash-offer">
                Get Our Instant Cash Offer
              </a>
              <a href="javascript:void(0)">Find another home value</a>
            </div>
          </div>
        );
      } else {
        return (
          <div className="new_banner_content_box">
            <div className="col-lg-12 mb-3">
              <div className="mb-4">
                <h4 className="mb-2 text-primary font-weight-bold">
                  We are unable to provide you with an offer at this point of
                  time. Don't worry, a RedBuyers advisor will get in touch with
                  you within 24 hours.
                </h4>
                <h4 className="text-dark font-weight-bold">
                  Thank you for choosing RedBuyers.
                </h4>
              </div>
            </div>
          </div>
        );
      }
    };
    return (
      <div className="mb-4" ref={ref}>
        <div className="position-sticky sticky_top_back_button pt-4">
          <button className="btn btn-primary d-none">Back</button>
        </div>
        <>
          <Banner className="new_banner button_new_cash_offer">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12" id="page_start">
                  {renderBannerContent()}
                </div>
              </div>
            </div>
          </Banner>

          <div className="new_selling_option">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="new_selling_option_title">
                    <h3>Your Selling Options with RedBuyers</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="new_selling_option_box">
                    <div className="new_selling_option_box_image">
                      <img src={so1Img} className="img-fluid" alt="" />
                    </div>
                    <div className="new_selling_option_box_content">
                      <div className="new_selling_option_box_content_first">
                        <span>Sell to RedBuyers</span>
                        <strong>Benefits:</strong>
                        <Benefits>
                          <li>Get A Cash Offer and Get Paid</li>
                          <li>Skip Showings & Repairs</li>
                          <li>Move On Your Schedule</li>
                        </Benefits>
                        <div className="new_selling_option_box_content_first_button">
                          <a href="https://www.redbuyers.com/sell-to-redbuyers-presentation">
                            Get My Cash Offer Price
                          </a>
                        </div>
                      </div>
                      <div className="new_selling_option_box_content_second">
                        <span>What’s Next ?</span>
                        <NextSteps>
                          <li>Show us your home</li>
                          <li>Finalize the cash offer</li>
                          <li>Sign the documents</li>
                          <li>Close and get paid</li>
                        </NextSteps>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="new_selling_option_box">
                    <div className="new_selling_option_box_image top-adjust">
                      <img src={so2Img} className="img-fluid" alt="" />
                    </div>
                    <div className="new_selling_option_box_content">
                      <div className="new_selling_option_box_content_first">
                        <span>List with RedBuyers</span>
                        <strong>Benefits:</strong>
                        <Benefits>
                          <li>Dedicated Local Experts</li>
                          <li>Technology-Driven Marketing</li>
                          <li>Your Home Sold Guaranteed</li>
                        </Benefits>
                        <div className="new_selling_option_box_content_first_button">
                          <a href="https://www.redbuyers.com/list-with-redbuyers-presentation">
                            Get My Sales Assurance Price
                          </a>
                        </div>
                      </div>
                      <div className="new_selling_option_box_content_second">
                        <span>What’s Next ?</span>
                        <NextSteps>
                          <li>Show us your home</li>
                          <li>List with us for a maximum price</li>
                          <li>Close and get paid</li>
                          <li>
                            Get a cash offer, and get paid, if it doesn’t sell
                            as expected
                          </li>
                        </NextSteps>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="new_schedule_appointment">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-9 order-2 order-md-1">
                  <div className="new_schedule_appointment_content">
                    <h4>Schedule An Appointment</h4>
                    <p>
                      A quick phone call to explain the whole process. We
                      collect more information about your home and upgrades to
                      provide an accurate price.
                    </p>
                    <a href="https://www.redbuyers.com/schedule/appointment">
                      Schedule an appointment
                    </a>
                  </div>
                </div>
                <div className="col-md-3 order-1 order-md-2">
                  <div className="new_schedule_appointment_image">
                    <img src={moneyImg} className="img-fluid" alt="money" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        <ValuationOfferTabs
          formFields={formFields}
          valuation={valuation}
        />
      </div>
    );
  }
);

export default FinalValuation;
