/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const NavbarContainer = styled.nav`
  padding: 0 6rem;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #261c1d;
  position: relative;
  @media screen and (max-width:1064px) {
    padding: 1rem 2rem;
  }
`;
export const MobileLinks = styled.div`
  /* opacity :${(prop) => (prop.open === true ? '1' : '0')}; */
  display: ${(prop) => (prop.open === true ? 'block' : 'none')};
  z-index: -100;
  padding: 4rem 2rem;
  gap: 1rem;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  background: #261c1d;
  button {
    font-size: 2rem;
    border-radius: 0.75rem;
    background: linear-gradient(95deg, #d9d9d9 0%, #fff 100%);
    padding: 0.9375rem 1.875rem;
    color: black;
  }
  img {
    position: absolute;
    top: 3rem;
    right: 3rem;
  }
  @media screen and (max-width: 915px) {
    padding-top: 8rem;
    div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    }
    button {
      font-size: 1.2rem;
    }
  }
    @media screen and (max-width: 435px) {
      button{
        padding: 0.7rem 2rem;
      }
  }
`;
export const Logo = styled(Link)`
  color: #fff;
  text-align: center;
  font-family: Impact;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform:uppercase;
  text-decoration: none;
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  /* a {
    color: #cbcbcb;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  } */
    @media screen and (max-width: 1150px) {
    gap: 1rem;
  }
  @media screen and (max-width: 942px) {
    display: none;
  }
`;

export const NavButton = styled.button`
  margin-left: 1rem;
  border-radius: 0.75rem;
  background: linear-gradient(95deg, #d9d9d9 0%, #fff 100%);
  padding: 0.8rem 1.875rem;
  color: black;
  @media screen and (max-width: 942px) {
    display: none;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: none;
  }
  @media screen and (max-width:942px) {
    img {
      display: block;
    }
  }
`;

export const NavLink = styled(Link)`
  color: #cbcbcb;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  @media screen and (max-width: 915px) {
    margin-top: 1rem;
    font-size: 2rem;
  }
  @media screen and (max-width: 435px) {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
`;
