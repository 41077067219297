/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;
  background-color: white;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  .content {
    padding: 1rem 2rem;
    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: #474747;
      font-family: DM Sans;
      font-size:clamp(0.75rem , 1vw,1rem);
      font-weight: 400;
      text-align: center;
    }
  }
  .imgContainer {
    padding: 1rem;
    img {
      border-radius: 50%;
    }
  }
  @media screen and (max-width: 915px) {
    width: 75%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
