import React, { useState } from "react";
import "./style.css";
import PropertyCard from "../../../../components/PropertyCard";

const tabs = [
  "Comparable home sales",
  "Currently For Sale",
  // "Market Trends",
  "Home Details",
];
const ValuationOfferTabs = ({
  formFields,
  valuation,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const renderTabItems = () => {
    return tabs.map((item, index) => {
      return (
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${currentTab === index ? "active" : ""}`}
            onClick={() => setCurrentTab(index)}
          >
            {item}
          </button>
        </li>
      );
    });
  };

  return (
    <div className="new_into_offer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="new_into_offer_title">
              <h5>What Went Into Your Offer</h5>
            </div>
          </div>
          <div className="col-md-12">
            <div className="new_into_offer_box">
              <div className="new_into_offer_box_button">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  {renderTabItems()}
                </ul>
              </div>
              <div className="new_into_offer_box_content">
                <div className="tab-content" id="pills-tabContent">
                  {currentTab === 0 && (
                    <div id="pills-comparable_home_sales" role="tabpanel">
                      <div
                        className="new_into_offer_box_content_box"
                        v-if="valuationPropertiesAvailable"
                      >
                        <div className="col-md-12">
                          <div className="common-property-list-grid">
                            {valuation?.properties?.map?.((property) => (
                              <PropertyCard
                                key={property?.id}
                                propertyDetails={property}
                                type="sold"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentTab === 1 && (
                    <div id="pills-currently_for_sale" role="tabpanel">
                      <div className="pills-currently_for_sale_row common-property-list-grid">
                        {valuation?.saleProperties?.map?.((property) => (
                          <PropertyCard
                            key={property?.id}
                            propertyDetails={property}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {currentTab === 4 && (
                    <div id="pills-market_trends" role="tabpanel">
                      <h3>{formFields?.address}</h3>
                      <div className="chart-wrapper bg-light">
                        <div className="row chart-info">
                          <div className="col-md-6 col-lg-5">
                            <div>
                              <div className="d-flex align-items-center">
                                <p className="mr-2 font-weight-bold text-black-50">
                                  Median sale price
                                </p>
                                &nbsp;&nbsp;
                                <p className="text-primary font-weight-bold">
                                  $123,123,00
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-5"></div>
                        </div>
                        <div className="chart-container">
                          <canvas id="market_trend_chart"></canvas>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentTab === 2 && (
                    <div id="pills-home_details" role="tabpanel">
                      <div className="home-details-tab__header border-bottom">
                        <h3 className="text-primary">Home details</h3>
                      </div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Bedrooms</th>
                            <th>Bathrooms</th>
                            <th>Square footage</th>
                            <th>Style of home</th>
                            <th>Garage spaces</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{formFields?.bedrooms}</td>
                            <td>{formFields?.bathrooms}</td>
                            <td>{formFields?.square_foot}</td>
                            <td>{formFields?.home_style}</td>
                            <td>{formFields?.garage}</td>
                          </tr>
                          {/* <tr>
                            <th>Extra details</th>
                            <td colsPan="4">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Placeat, ipsam?
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationOfferTabs;
