/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 1px solid #d9d9d9;
  margin: 2rem 0;
  /* padding: 2rem 1rem; */
  padding-bottom: 10px;
  .Ans {
    margin-top: 1rem;
    width: 75%;
  } @media screen and (max-width: 475px) {
    .Ans{
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .heading {
    color: #000;
    font-family: DM Sans;
    font-size:clamp(1.2rem, 1.2vw, 3.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  p {
    color: #474747;
    font-family: DM Sans;
    font-size: clamp(0.75rem , 1vw,1rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
   @media screen and (max-width: 475px) {
    /* .heading{
      font-size: 1.3rem;
    } */
  }
`;
