/** @format */

import React from 'react';
import { Container } from './IncomeCard.style';
export default function IncomeCard({ heading, image, paragraph }) {
    return (
        <Container>
            <h1>{heading}</h1>
            <div className="imageContainer">
                <img src={image} alt="IncomeImage" />
            </div>
            <p>{paragraph}</p>
        </Container>
    );
}
