/** @format */

import React from 'react';
import ImageContainer from '../ImageContainer/ImageContainer.component';
import Img1 from './Images/Agent-Image-1.jpg';
import Img2 from './Images/Agent-Image-2.jpg';
import Img3 from './Images/Agent-image-3.jpg';
import { Container, Heading, Content } from './Agent.style';
export default function Agent() {
    return (
        <Container>
            <Heading>
                <h1>How Housechat is Unique</h1>
                <p>
                    we provide solutions to sophisticated real estate, which is controlled
                    by Realtors and MLS system to provide easy and affordable way to buy,
                    sell and invest with our technology platform
                </p>
            </Heading>
            <Content>
                <ImageContainer
                    heading={'Featured Property'}
                    Images={Img1}
                    Link={'/'}
                />
                <ImageContainer
                    heading={'Home Collections'}
                    Images={Img2}
                    Link={'/browse-homes'}
                />
                <ImageContainer
                    heading={'Instant Home value'}
                    Images={Img3}
                    Link={'/find-home-value'}
                />
            </Content>
        </Container>
    );
}
