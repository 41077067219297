/** @format */

import React, { useState } from 'react';
import {
    NavbarContainer,
    NavButton,
    Links,
    Logo,
    LinksContainer,
    MobileLinks,
    NavLink,
} from './Navbar.style';
import HamburgerIcon from '../../assets/Images/ri_menu-line.png';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer.component';
import ContactForm from '../Contact-form/ContactForm.compoent';
export default function Navbar() {
    const [hamIcon, sethamIcon] = useState(false);
    console.log(hamIcon);
    const toggleNavbar = () => {
        // console.log(hamIcon);
        sethamIcon(!hamIcon);
    };
    return (
        <>
            <NavbarContainer>
                <Logo to="/">HouseChat</Logo>
                <LinksContainer>
                    <Links>
                        <NavLink to="/associate">Become An Associate</NavLink>
                        <NavLink to="/benefits">Associates benefits</NavLink>
                        <NavLink to="/buy-sell" > Ways to Buy/Sell</NavLink>
                        <NavLink to="/browse-homes">Browse home</NavLink>
                    </Links>
                    <NavButton>Login</NavButton>
                    <img
                        onClick={toggleNavbar}
                        src={HamburgerIcon}
                        alt="Icon"
                    />
                </LinksContainer>
                <MobileLinks open={hamIcon}>
                    <div>
                        <NavLink to="/associate" onClick={toggleNavbar}>Become An Associate</NavLink>
                        <NavLink to="/benefits" onClick={toggleNavbar}>Associates benefits</NavLink>
                        <NavLink to="/buy-sell" onClick={toggleNavbar}> Ways to Buy/Sell</NavLink>
                        <NavLink to="/browse-homes" onClick={toggleNavbar}>Browse home</NavLink>
                        <button>Login</button>
                    </div>
                    <img
                        onClick={toggleNavbar}
                        src={HamburgerIcon}
                        alt="Icon"
                    />
                </MobileLinks>
            </NavbarContainer>
            <Outlet />
            {/* <ContactForm /> */}
            <Footer />
        </>
    );
}
