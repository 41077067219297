/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled(Link)`
  position: relative;
  /* width:calc(33.33% - 5rem); */
  flex: 1;
  height: 26rem;
  background-image: url(${(props) => props.CImages});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  filter: brightness(100%); 
  h1 {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: 3rem;
    color: #fff;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.3
    ); /* Adjust the alpha (fourth) value for the desired level of transparency */
  }
  @media screen and (max-width: 1070px) {
    width: calc(33.33% - 2rem);
    gap: 2rem;
  }
  @media screen and (max-width: 915px) {
    width: 100%;
    height: 20rem;
    /* h1 {
      font-size: 2rem;
    } */
  }
`;
