/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.footer`
  height: 100%;
  padding: 2rem 9rem;
  background-color: #261c1d;
  margin-top: 4rem;
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;
    .left-side {
      width: 35%;
      h1 {
        color: var(--Template-1---White, #fdfdfe);
        font-family: DM Sans;
        font-size: 2.94688rem;
        font-weight: 400;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 2rem;
        color: var(--Template-1-Main-Tint, #eef0f4);
        font-family: DM Sans;
        font-size: 1.125rem;
        font-weight: 400;
      }
      .navigation {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        .navigation_container {
          width: 3rem;
          height: 3rem;
          padding: 1rem;
          background-color: #4b3c3d;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
        }
      }
    }

    .right-side {
      display: grid;
      /* grid-template-columns: 1fr); */
       .footer-link:nth-child(1) {
        grid-area: Benefits;
      }
       .footer-link:nth-child(2) {
        grid-area: Services;
      }
       .footer-link:nth-child(3) {
        grid-area: Policies;
      }
       .footer-link:nth-child(4) {
        grid-area: Aboutus;
      }
      grid-template-areas:
          'Benefits Services Policies Aboutus';
      
      gap: 1.5rem;
      .footer-link {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        h1 {
          color: var(--Template-1-Main-Tint, #eef0f4);
          font-family: DM Sans;
          font-size: 1.25rem;
          font-weight: 700;
          line-height: 2rem; /* 160% */
          letter-spacing: -0.025rem;
        }
        p {
          color: var(--Inactive-Grey, #b2b2b2);
          font-family: DM Sans;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2rem; /* 177.778% */
        }
      }
    }
  }
  @media screen and (max-width: 1370px) {
    padding: 2rem;
    height: 100%;
    .main {
      /* flex-direction: column; */
      .left-side {
        width: 100%;
        margin-bottom: 2rem;
      }
      .right-side {
        width: 100%;
        align-items: flex-start;
        flex-wrap: wrap;
        grid-template-areas:
          'Benefits Policies'
          'Services Aboutus';
      }
    }
  }
  @media screen and (max-width:818px) {
      .main{
        flex-direction: column;
      }
  }
  @media screen and (max-width: 458px) {
    .main {
      .left-side {
        h1 {
          font-size: 1.7rem;
        }
      }
      .right-side{
            gap: 1rem;
          grid-template-areas:
          'Benefits' 
          'Policies'
          'Services' 
          'Aboutus';
      }
      }
    }
`;

export const Content = styled.div`
  margin-top: 4rem;
  p {
    color: var(--Template-1-Main-Tint, #eef0f4);
    font-family: DM Sans;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  .copy_right {
    text-align: center;
    color: var(--Template-1-Main-Tint, #eef0f4);
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.33331rem; /* 133.333% */
  }
`;

export const FooterLink = styled(Link)`
  color: var(--Inactive-Grey, #b2b2b2);
  font-family: DM Sans;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2rem; /* 177.778% */
  text-decoration: none;
`;
