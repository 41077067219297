import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import pick from "lodash/pick";

import "./style.css";

const sortByOptions = [
  "Recommended",
  "Most Recent",
  "Oldest",
  "Most Expensive",
  "Least Expensive",
  "Most Bedroom",
];
const homeTypesOptions = [
  { label: "Any", value: "" },
  { label: "Detached", value: "Detached" },
  { label: "Semi-Detached", value: "Semi-Detached" },
  { label: "Town House", value: "Att/Row/Twnhouse" },
  { label: "Condo Town House", value: "Condo Townhouse" },
  { label: "Condo Apartment", value: "Condo Apt" },
];

const homeStyleOptions = [
  { label: "Any", value: "" },
  { label: "2-Storey", value: "2-Storey" },
  { label: "3-Storey", value: "3-Storey" },
  { label: "Bungalow", value: "Bungalow" },
  { label: "Split level", value: "Split level" },
  { label: "Other", value: "Other" },
];
const bedBathOptions = [
  { label: "Any", value: "" },
  { label: "1+", value: "1+" },
  { label: "2+", value: "2+" },
  { label: "3+", value: "3+" },
  { label: "4+", value: "4+" },
  { label: "5+", value: "5+" },
];

const priceOptions = ["0-500", "500-1M", "1M-1.5M"];


const ListFilter = ({ filterData, setFilterData }) => {
  const [showCityDropdown, setShowCityDropdown] = useState(false);
  const [showSortByDropdown, setSortByDropdown] = useState(false);
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const [selectedSortByOption, setSortByOption] = useState("Most Recent");
  const [selectedPriceOption, setPriceOption] = useState("");
  const [priceInputs, setPriceInputs] = useState({
    min_price: "",
    max_price: "",
  });
  const [cityInput, setCityInput] = useState(filterData?.city || "");
  const [cityList, setCityList] = useState([
    { city: "Toronto", count: 34000 },
    { city: "Mississauga", count: 6420 },
    { city: "Vaughan", count: 3826 },
    { city: "Brampton", count: 3813 },
    { city: "Markham", count: 3527 },
    { city: "Hamilton", count: 3238 },
    { city: "Oakville", count: 2719 },
    { city: "Barrie", count: 2387 },
    { city: "Richmond Hill", count: 2244 },
    { city: "Oshawa", count: 1631 },
    { city: "Burlington", count: 1463 },
    { city: "Milton", count: 1138 },
    { city: "Whitby", count: 955 },
    { city: "Kitchener", count: 940 },
    { city: "Innisfil", count: 872 },
    { city: "Newmarket", count: 847 },
    { city: "Caledon", count: 804 },
    { city: "Kawartha Lakes", count: 801 },
    { city: "Pickering", count: 790 },
    { city: "Clarington", count: 732 },
    { city: "Niagara Falls", count: 724 },
    { city: "Ajax", count: 677 },
    { city: "St. Catharines", count: 612 },
    { city: "Georgina", count: 604 },
    { city: "Aurora", count: 595 },
    { city: "London", count: 577 },
    { city: "Brantford", count: 565 },
    { city: "Cambridge", count: 555 },
    { city: "Whitchurch-Stouffville", count: 517 },
    { city: "Belleville", count: 516 },
    { city: "Halton Hills", count: 500 },
    { city: "Guelph", count: 489 },
    { city: "Prince Edward County", count: 482 },
    { city: "East Gwillimbury", count: 462 },
    { city: "Bradford West Gwillimbury", count: 457 },
    { city: "Waterloo", count: 432 },
    { city: "Wasaga Beach", count: 424 },
    { city: "New Tecumseth", count: 417 },
    { city: "Peterborough", count: 396 },
    { city: "Welland", count: 373 },
    { city: "Orillia", count: 349 },
    { city: "Quinte West", count: 342 },
    { city: "Fort Erie", count: 338 },
    { city: "King", count: 327 },
    { city: "Grimsby", count: 316 },
    { city: "Collingwood", count: 315 },
    { city: "Springwater", count: 312 },
    { city: "Oro-Medonte", count: 297 },
    { city: "Cobourg", count: 291 },
    { city: "Haldimand", count: 282 },
    { city: "Orangeville", count: 266 },
    { city: "Thorold", count: 265 },
    { city: "Tiny", count: 256 },
    { city: "Woodstock", count: 250 },
    { city: "Brant", count: 249 },
    { city: "Severn", count: 238 },
    { city: "Blue Mountains", count: 223 },
    { city: "Ramara", count: 217 },
    { city: "Norfolk", count: 206 },
    { city: "Uxbridge", count: 202 },
    { city: "Trent Hills", count: 200 },
    { city: "Midland", count: 199 },
    { city: "Shelburne", count: 197 },
    { city: "", count: 196 },
    { city: "Ottawa", count: 192 },
    { city: "Lincoln", count: 191 },
    { city: "Clearview", count: 181 },
    { city: "Niagara-on-the-Lake", count: 179 },
    { city: "Essa", count: 175 },
    { city: "Scugog", count: 175 },
    { city: "Brock", count: 164 },
    { city: "Southgate", count: 153 },
    { city: "Erin", count: 135 },
    { city: "Windsor", count: 135 },
    { city: "Brighton", count: 130 },
    { city: "Tay", count: 130 },
    { city: "Gravenhurst", count: 128 },
    { city: "Kingston", count: 126 },
    { city: "Centre Wellington", count: 125 },
    { city: "Chatham-Kent", count: 124 },
    { city: "Adjala-Tosorontio", count: 121 },
    { city: "Port Colborne", count: 121 },
    { city: "Alnwick/Haldimand", count: 118 },
    { city: "Cramahe", count: 116 },
    { city: "Smith-Ennismore-Lakefield", count: 115 },
    { city: "Port Hope", count: 112 },
    { city: "Grey Highlands", count: 110 },
    { city: "Huntsville", count: 102 },
    { city: "Out of Area", count: 102 },
    { city: "Hamilton Township", count: 101 },
    { city: "Penetanguishene", count: 99 },
    { city: "Galway-Cavendish and Harvey", count: 98 },
    { city: "Mono", count: 89 },
    { city: "Guelph/Eramosa", count: 88 },
    { city: "Pelham", count: 84 },
    { city: "Meaford", count: 80 },
    { city: "Tweed", count: 78 },
    { city: "Otonabee-South Monaghan", count: 76 },
    { city: "Cavan Monaghan", count: 74 },
    { city: "Bracebridge", count: 70 },
    { city: "Greater Napanee", count: 70 },
    { city: "Puslinch", count: 70 },
    { city: "West Grey", count: 70 },
    { city: "Madoc", count: 69 },
    { city: "Marmora and Lake", count: 69 },
    { city: "West Lincoln", count: 69 },
    { city: "Mulmur", count: 64 },
    { city: "Amaranth", count: 63 },
    { city: "East Luther Grand Valley", count: 63 },
    { city: "Stirling-Rawdon", count: 63 },
    { city: "Havelock-Belmont-Methuen", count: 59 },
    { city: "Tillsonburg", count: 59 },
    { city: "Muskoka Lakes", count: 57 },
    { city: "Wellington North", count: 57 },
    { city: "Bancroft", count: 56 },
    { city: "Georgian Bay", count: 56 },
    { city: "Northern Bruce Peninsula", count: 51 },
    { city: "Stratford", count: 51 },
    { city: "Highlands East", count: 50 },
    { city: "South Bruce Peninsula", count: 48 },
    { city: "Wainfleet", count: 48 },
    { city: "Douro-Dummer", count: 47 },
    { city: "North Kawartha", count: 47 },
    { city: "North Perth", count: 47 },
    { city: "Woolwich", count: 47 },
    { city: "Melancthon", count: 45 },
    { city: "Parry Sound", count: 44 },
    { city: "St. Thomas", count: 44 },
    { city: "Minden Hills", count: 43 },
    { city: "North Dumfries", count: 43 },
    { city: "Tyendinaga", count: 43 },
    { city: "Addington Highlands", count: 41 },
    { city: "Asphodel-Norwood", count: 41 },
    { city: "Hanover", count: 41 },
    { city: "Owen Sound", count: 40 },
    { city: "Timmins", count: 40 },
    { city: "Chatsworth", count: 35 },
    { city: "Georgian Bluffs", count: 35 },
    { city: "Sarnia", count: 32 },
    { city: "Dysart et al", count: 30 },
    { city: "Stone Mills", count: 30 },
    { city: "East Garafraxa", count: 29 },
    { city: "Ingersoll", count: 29 },
    { city: "Sudbury Remote Area", count: 29 },
    { city: "Costa Rica", count: 28 },
    { city: "Loyalist", count: 28 },
    { city: "South Frontenac", count: 27 },
    { city: "Cornwall", count: 26 },
    { city: "Greater Sudbury", count: 26 },
    { city: "Centre Hastings", count: 25 },
    { city: "Sault Ste Marie", count: 25 },
    { city: "Kirkland Lake", count: 24 },
    { city: "Hastings Highlands", count: 23 },
    { city: "North Frontenac", count: 23 },
    { city: "Saugeen Shores", count: 23 },
    { city: "Seguin", count: 23 },
    { city: "Wilmot", count: 21 },
    { city: "Brockton", count: 20 },
    { city: "Central Frontenac", count: 20 },
    { city: "Lake of Bays", count: 20 },
    { city: "Minto", count: 20 },
    { city: "Parry Sound Remote Area", count: 19 },
    { city: "Strathroy-Caradoc", count: 19 },
    { city: "Leeds & the Thousand Island", count: 18 },
    { city: "North Bay", count: 18 },
    { city: "Southwold", count: 18 },
    { city: "Tudor & Cashel", count: 18 },
    { city: "West Nipissing", count: 18 },
    { city: "Algonquin Highlands", count: 17 },
    { city: "Mapleton", count: 17 },
    { city: "Perth East", count: 17 },
    { city: "Amherstburg", count: 16 },
    { city: "Carling", count: 16 },
    { city: "The Archipelago", count: 16 },
    { city: "West Perth", count: 16 },
    { city: "Wollaston", count: 16 },
    { city: "South River", count: 15 },
    { city: "Zorra", count: 15 },
    { city: "Gananoque", count: 14 },
    { city: "Lambton Shores", count: 14 },
    { city: "LaSalle", count: 14 },
    { city: "McDougall", count: 14 },
    { city: "Thames Centre", count: 14 },
    { city: "Brockville", count: 13 },
    { city: "McMurrich/Monteith", count: 13 },
    { city: "Central Elgin", count: 12 },
    { city: "Georgina Islands", count: 12 },
    { city: "Goderich", count: 12 },
    { city: "Kingsville", count: 12 },
    { city: "Magnetawan", count: 12 },
    { city: "Mexico", count: 12 },
    { city: "Faraday", count: 11 },
    { city: "Kearney", count: 11 },
    { city: "Kincardine", count: 11 },
    { city: "Lakeshore", count: 11 },
    { city: "South Bruce", count: 11 },
    { city: "Thunder Bay", count: 11 },
    { city: "Aylmer", count: 10 },
    { city: "Espanola", count: 10 },
    { city: "Middlesex Centre", count: 10 },
    { city: "Plympton-Wyoming", count: 10 },
    { city: "Madawaska Valley", count: 9 },
    { city: "McKellar", count: 9 },
    { city: "Powassan", count: 9 },
    { city: "Renfrew", count: 9 },
    { city: "Rideau Lakes", count: 9 },
    { city: "Smiths Falls", count: 9 },
    { city: "Whitestone", count: 9 },
    { city: "Blandford-Blenheim", count: 8 },
    { city: "Burk's Falls", count: 8 },
    { city: "Central Manitoulin", count: 8 },
    { city: "Leamington", count: 8 },
    { city: "North Middlesex", count: 8 },
    { city: "Pembroke", count: 8 },
    { city: "South Huron", count: 8 },
    { city: "St. Marys", count: 8 },
    { city: "Arran-Elderslie", count: 7 },
    { city: "Bluewater", count: 7 },
    { city: "Dutton/Dunwich", count: 7 },
    { city: "North Huron", count: 7 },
    { city: "Perth", count: 7 },
    { city: "West Elgin", count: 7 },
    { city: "Armour", count: 6 },
    { city: "Curve Lake First Nation 35", count: 6 },
    { city: "East Zorra-Tavistock", count: 6 },
    { city: "Elliot Lake", count: 6 },
    { city: "Essex", count: 6 },
    { city: "Morris-Turnberry", count: 6 },
    { city: "Norwich", count: 6 },
    { city: "Wellesley", count: 6 },
    { city: "Whitewater Region", count: 6 },
    { city: "Bayham", count: 5 },
    { city: "Bonnechere Valley", count: 5 },
    { city: "Cochrane", count: 5 },
    { city: "Limerick", count: 5 },
    { city: "Lucan Biddulph", count: 5 },
    { city: "Markstay-Warren", count: 5 },
    { city: "North Dundas", count: 5 },
    { city: "Panama", count: 5 },
    { city: "Quebec", count: 5 },
    { city: "Thessalon", count: 5 },
    { city: "Alfred & Plantagenet", count: 4 },
    { city: "Brooke-Alvinston", count: 4 },
    { city: "Callander", count: 4 },
    { city: "Deseronto", count: 4 },
    { city: "Elizabethtown-Kitley", count: 4 },
    { city: "Hiawatha First Nation", count: 4 },
    { city: "Hornepayne", count: 4 },
    { city: "Huron East", count: 4 },
    { city: "Malahide", count: 4 },
    { city: "Nipissing", count: 4 },
    { city: "Northeastern Manitoulin and", count: 4 },
    { city: "Petrolia", count: 4 },
    { city: "Smooth Rock Falls", count: 4 },
    { city: "South-West Oxford", count: 4 },
    { city: "Sundridge", count: 4 },
    { city: "Tay Valley", count: 4 },
    { city: "Tecumseh", count: 4 },
    { city: "Temagami", count: 4 },
    { city: "Augusta", count: 3 },
    { city: "Brudenell, Lyndoch and Ragl", count: 3 },
    { city: "Central Huron", count: 3 },
    { city: "First Nations", count: 3 },
    { city: "French River", count: 3 },
    { city: "Front of Yonge", count: 3 },
    { city: "Frontenac Islands", count: 3 },
    { city: "Gauthier", count: 3 },
    { city: "Huron Shores", count: 3 },
    { city: "Huron-Kinloss", count: 3 },
    { city: "Kenora", count: 3 },
    { city: "Killaloe, Hagarty & Richard", count: 3 },
    { city: "McNab/Braeside", count: 3 },
    { city: "Mississippi Mills", count: 3 },
    { city: "Montreal", count: 3 },
    { city: "North Glengarry", count: 3 },
    { city: "Schreiber", count: 3 },
    { city: "South Algonquin", count: 3 },
    { city: "South Dundas", count: 3 },
    { city: "Strong", count: 3 },
    { city: "Admaston/Bromley", count: 2 },
    { city: "Arnprior", count: 2 },
    { city: "Ashfield-Colborne-Wawanosh", count: 2 },
    { city: "Assiginack", count: 2 },
    { city: "Blind River", count: 2 },
    { city: "Bonfield", count: 2 },
    { city: "Carlow/Mayo", count: 2 },
    { city: "Chapleau", count: 2 },
    { city: "Clarence-Rockland", count: 2 },
    { city: "Dawson", count: 2 },
    { city: "Drummond/North Elmsley", count: 2 },
    { city: "Englehart", count: 2 },
    { city: "Fort Frances", count: 2 },
    { city: "France", count: 2 },
    { city: "Greenstone", count: 2 },
    { city: "Hearst", count: 2 },
    { city: "Horton", count: 2 },
    { city: "Iroquois Falls", count: 2 },
    { city: "Lanark Highlands", count: 2 },
    { city: "Latchford", count: 2 },
    { city: "Matachewan", count: 2 },
    { city: "Mattawa", count: 2 },
    { city: "New Brunswick", count: 2 },
    { city: "North Grenville", count: 2 },
    { city: "North Stormont", count: 2 },
    { city: "Oil Springs", count: 2 },
    { city: "Papineau-Cameron", count: 2 },
    { city: "Perry", count: 2 },
    { city: "Prince Edward Island", count: 2 },
    { city: "Roma/Italy", count: 2 },
    { city: "Sables-Spanish Rivers", count: 2 },
    { city: "South Glengarry", count: 2 },
    { city: "South Stormont", count: 2 },
    { city: "St. Clair", count: 2 },
    { city: "Temiskaming Shores", count: 2 },
    { city: "The North Shore", count: 2 },
    { city: "Westport", count: 2 },
    { city: "Alderville First Nation", count: 1 },
    { city: "Baldwin", count: 1 },
    { city: "Barbados", count: 1 },
    { city: "Black River-Matheson", count: 1 },
    { city: "Calvin", count: 1 },
    { city: "Champlain", count: 1 },
    { city: "Chisholm", count: 1 },
    { city: "Christian Island 30", count: 1 },
    { city: "Coleman", count: 1 },
    { city: "Dawn-Euphemia", count: 1 },
    { city: "Deep River", count: 1 },
    { city: "Dominican", count: 1 },
    { city: "Dryden", count: 1 },
    { city: "East Hawkesbury", count: 1 },
    { city: "Enniskillen", count: 1 },
    { city: "Fauquier-Strickland", count: 1 },
    { city: "Florida  Usa", count: 1 },
    { city: "Gore Bay", count: 1 },
    { city: "Greater Madawaska", count: 1 },
    { city: "Howick", count: 1 },
    { city: "Ignace", count: 1 },
    { city: "Joly", count: 1 },
    { city: "Kapuskasing", count: 1 },
    { city: "Kelowna", count: 1 },
    { city: "Kerns", count: 1 },
    { city: "Killarney", count: 1 },
    { city: "Mattice-Val Cote", count: 1 },
    { city: "McGarry", count: 1 },
    { city: "Nairn & Hyman", count: 1 },
    { city: "Nation", count: 1 },
    { city: "Newbury", count: 1 },
    { city: "Nipigon", count: 1 },
    { city: "Nipissing Remote Area", count: 1 },
    { city: "Opasatika", count: 1 },
    { city: "Petawawa", count: 1 },
    { city: "Point Edward", count: 1 },
    { city: "Portugal", count: 1 },
    { city: "Prescott", count: 1 },
    { city: "Punjab", count: 1 },
    { city: "Rama First Nation 32", count: 1 },
    { city: "Russell", count: 1 },
    { city: "Ryerson", count: 1 },
    { city: "Sioux Lookout", count: 1 },
    { city: "Southwest Middlesex", count: 1 },
    { city: "Spanish", count: 1 },
    { city: "Tehkummah", count: 1 },
    { city: "Timiskaming Remote Area", count: 1 },
    { city: "White River", count: 1 },
  ]);
  const [filterState, setFilterState] = useState({ ...filterData });

  const filteredCities = useMemo(() => {
    return cityList?.filter((city) =>
      city.city.toLowerCase().startsWith(cityInput.toLowerCase())
    );
  }, [cityInput, cityList]);

  const updateFilterData = (newData) => {
    setFilterData((prev) => ({ ...prev, ...newData }));
  };
  const updateFilterState = (newData) => {
    setFilterState((prev) => ({ ...prev, ...newData }));
  };

  const openCityDropdown = () => {
    setShowCityDropdown(true);
  };

  const closeCityDropdown = () => {
    setTimeout(() => {
      setShowCityDropdown(false);
    }, 300);
  };

  const handleSelectCity = (e, city) => {
    e.preventDefault();
    setCityInput(city);
  };

  const handleSearch = () => {
    if (cityInput) updateFilterData({ city: cityInput });
  };

  const handleChangeBuyRent = (val) => {
    updateFilterData({ sale_or_lease: val });
  };

  const handlePriceTagChange = (val) => {
    const [min, max = ""] = val.split("-");

    const min_price = getPriceToNumber(min);
    const max_price = getPriceToNumber(max);

    setPriceOption(val);
    setPriceInputs({ min_price: min, max_price: max });
    updateFilterState({ min_price, max_price });
  };

  const handleMinPriceInputChange = (e) => {
    const minPriceInNumber = getPriceToNumber(e.target.value);
    const maxPriceInNumber = getPriceToNumber(priceInputs.max_price);

    let maxPriceInput = priceInputs.max_price;
    let minPriceInput = e.target.value;

    if (maxPriceInNumber != "" && minPriceInNumber > maxPriceInput) {
      maxPriceInput = e.target.value;
      minPriceInput = priceInputs.max_price;
      setPriceInputs({ min_price: minPriceInput, max_price: maxPriceInput });
    } else {
      setPriceInputs({ min_price: minPriceInput, max_price: maxPriceInput });
    }

    const min_price = getPriceToNumber(minPriceInput);
    const max_price = getPriceToNumber(maxPriceInput);
    updateFilterState({ min_price, max_price });

    const minMax = `${minPriceInput}-${maxPriceInput}`;
    if (Object.values(priceOptions).includes(minMax)) {
      setPriceOption(minMax);
    } else {
      setPriceOption("");
    }
  };

  const handleMaxPriceInputChange = (e) => {
    const maxPriceInNumber = getPriceToNumber(e.target.value);
    const minPriceInNumber = getPriceToNumber(priceInputs.min_price);
    let maxPriceInput = e.target.value;
    let minPriceInput = priceInputs.min_price;

    if (minPriceInNumber !== "" && minPriceInNumber > maxPriceInNumber) {
      maxPriceInput = e.target.value;
      minPriceInput = minPriceInput;
    } else {
      setPriceInputs({ min_price: minPriceInput, max_price: maxPriceInput });
    }

    const min_price = getPriceToNumber(minPriceInput);
    const max_price = getPriceToNumber(maxPriceInput);
    updateFilterState({ min_price, max_price });

    const minMax = `${minPriceInput}-${maxPriceInput}`;
    if (Object.values(priceOptions).includes(minMax)) {
      setPriceOption(minMax);
    } else {
      setPriceOption("");
    }
  };

  const getPriceToNumber = (val) => {
    if (val == "0") {
      return 0;
    } else if (val == "500") {
      return 500000;
    } else if (val == "1M") {
      return 1000000;
    } else if (val == "1.5M") {
      return 1500000;
    } else if (val == "2M") {
      return 2000000;
    } else if (val == "3M") {
      return 3000000;
    } else {
      return "";
    }
  };

  const handleSortFilterChange = (val) => {
    let sortBy = "";
    let sortDir = "";
    if (val === "Most Recent") {
      sortBy = "timestamp_idx";
      sortDir = "desc";
    } else if (val === "Oldest") {
      sortBy = "timestamp_idx";
      sortDir = "asc";
    } else if (val === "Most Expensive") {
      sortBy = "list_price";
      sortDir = "desc";
    } else if (val === "Least Expensive") {
      sortBy = "list_price";
      sortDir = "asc";
    } else if (val === "Most Bedroom") {
      sortBy = "bedrooms";
      sortDir = "desc";
    } else {
      sortBy = "list_price";
      sortDir = "desc";
    }
    setSortByOption(val);
    updateFilterData({ sortBy, sortDir });
    setSortByDropdown(false); // close dropdown
  };

  const handleFilterChange = (filterName, filterValue) => {
    updateFilterData({ [filterName]: filterValue });
    // setFilterDropdown(false)
  };

  const resetFilter = () => {
    const fields = {
      property_type: "",
      property_style: "",
      bedrooms: "",
      bathrooms: "",
      min_price: "",
      max_price: "",
    };
    updateFilterData(fields);
    updateFilterState(fields);
    setFilterDropdown(false);
  };

  const submit = () => {
    const pickData = pick(filterState, [
      "property_type",
      "property_style",
      "bedrooms",
      "bathrooms",
      "min_price",
      "max_price",
    ]);
    updateFilterData(pickData);
    setFilterDropdown(false);
  };

  const cancelFilter = () => {
    const pickData = pick(filterData, [
      "property_type",
      "property_style",
      "bedrooms",
      "bathrooms",
      "min_price",
      "max_price",
    ]);
    updateFilterState(pickData);
    setFilterDropdown(false);
  };

  return (
    <div className="filter-list-header">
      <div className="container filter-list-header-container">
        <div className="search-box me-2">
          <svg
            className="svg-inline--fa fa-map-marker-alt fa-w-12"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="map-marker-alt"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
            />
          </svg>
          <input
            type="text"
            placeholder="Where’s your house?"
            onFocus={openCityDropdown}
            onBlur={closeCityDropdown}
            value={cityInput}
            onChange={(e) => setCityInput(e.target.value)}
          />
          <Dropdown>
            <Dropdown.Toggle as="button">
              {filterData?.sale_or_lease === "Sale" ? "Buy" : "Rent"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                active={filterData?.sale_or_lease === "Sale"}
                onClick={() => handleChangeBuyRent("Sale")}
              >
                Buy
              </Dropdown.Item>
              <Dropdown.Item
                active={filterData?.sale_or_lease === "Lease"}
                onClick={() => handleChangeBuyRent("Lease")}
              >
                Rent
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button className="search-btn btn-md" onClick={handleSearch}>
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="d-inline-block d-sm-none"
            >
              <path
                d="M12.9179 11.6669H12.2595L12.0262 11.4419C12.547 10.8369 12.9276 10.1242 13.1408 9.3549C13.354 8.5856 13.3946 7.77868 13.2595 6.99189C12.8679 4.67522 10.9345 2.82522 8.6012 2.54189C7.78088 2.43811 6.94769 2.52337 6.16539 2.79113C5.38308 3.0589 4.6724 3.50207 4.08773 4.08675C3.50305 4.67143 3.05987 5.38211 2.79211 6.16441C2.52434 6.94671 2.43909 7.7799 2.54287 8.60022C2.8262 10.9336 4.6762 12.8669 6.99287 13.2586C7.77966 13.3936 8.58658 13.3531 9.35588 13.1398C10.1252 12.9266 10.8378 12.546 11.4429 12.0252L11.6679 12.2586V12.9169L15.2095 16.4586C15.5512 16.8002 16.1095 16.8002 16.4512 16.4586C16.7929 16.1169 16.7929 15.5586 16.4512 15.2169L12.9179 11.6669ZM7.91787 11.6669C5.84287 11.6669 4.16787 9.99189 4.16787 7.91689C4.16787 5.84189 5.84287 4.16689 7.91787 4.16689C9.99287 4.16689 11.6679 5.84189 11.6679 7.91689C11.6679 9.99189 9.99287 11.6669 7.91787 11.6669Z"
                fill="white"
              />
            </svg>
            <span className="btn-text">Search</span>
          </button>
          {showCityDropdown && filteredCities?.length ? (
            <div className="list-group city-dropdown">
              {filteredCities?.map((city) => (
                <a
                  key={city.city}
                  className="list-group-item list-group-item-action"
                  onClick={(e) => handleSelectCity(e, city.city)}
                >
                  {city?.city}
                </a>
              ))}
            </div>
          ) : null}
        </div>

        <Dropdown
          className="dropdown dropdown__filter me-1"
          show={showFilterDropdown}
          onToggle={setFilterDropdown}
        >
          <Dropdown.Toggle className="btn btn-md filter-sort-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              className="me-1"
            >
              <g clipPath="url(#clip0_1076_3980)">
                <path
                  d="M3.07692 8.61527C4.22398 8.61527 5.15385 7.6854 5.15385 6.53835C5.15385 5.3913 4.22398 4.46143 3.07692 4.46143C1.92987 4.46143 1 5.3913 1 6.53835C1 7.6854 1.92987 8.61527 3.07692 8.61527Z"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.07812 8.61539V19M3.07812 1V4.46154"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.9226 8.61527C18.0697 8.61527 18.9996 7.6854 18.9996 6.53835C18.9996 5.3913 18.0697 4.46143 16.9226 4.46143C15.7756 4.46143 14.8457 5.3913 14.8457 6.53835C14.8457 7.6854 15.7756 8.61527 16.9226 8.61527Z"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.9238 4.46154V1M16.9238 19V8.61539"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0008 12.0767C11.1478 12.0767 12.0777 11.1468 12.0777 9.99978C12.0777 8.85272 11.1478 7.92285 10.0008 7.92285C8.8537 7.92285 7.92383 8.85272 7.92383 9.99978C7.92383 11.1468 8.8537 12.0767 10.0008 12.0767Z"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 1V7.92308M10 12.0769V19"
                  stroke="#0061DF"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1076_3980">
                  <rect width={20} height={20} fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="btn-text">Filter</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="mb-4 d-flex align-items-center justify-content-between">
              <p className="mb-0">
                <span className="text-primary">Filters</span>
                <span className="text-muted">(120,000 Results)</span>
              </p>
              <button
                className="btn btn-link text-primary p-0 text-decoration-underline"
                onClick={resetFilter}
              >
                Reset Filters
              </button>
            </div>
            <div className="filter-field mb-3">
              <label>House Type</label>
              <div className="filter-field__options">
                {homeTypesOptions?.map((option) => (
                  <button
                    key={option.label}
                    className={`btn btn-sm btn-grey ${
                      filterState?.property_type === option.value
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      updateFilterState({ property_type: option.value })
                    }
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="filter-field mb-3">
              <label>House Style</label>
              <div className="filter-field__options">
                {homeStyleOptions?.map((option) => (
                  <button
                    key={option.label}
                    className={`btn btn-sm btn-grey ${
                      filterState?.property_style === option.value
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      updateFilterState({ property_style: option.value })
                    }
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="filter-field  mb-3">
              <label>Price Range</label>
              <div className="price-range-wrapper">
                <div className="price-range-select-group">
                  <div className="form-group">
                    <select
                      name="min_price_input"
                      className="form-control"
                      value={priceInputs.min_price}
                      onChange={handleMinPriceInputChange}
                    >
                      <option value="">No Min</option>
                      <option value="0">0</option>
                      <option value="500">500</option>
                      <option value="1M">1M</option>
                      <option value="1.5M">1.5M</option>
                      <option value="2M">2M</option>
                      <option value="3M">3M</option>
                    </select>
                  </div>
                  <span className="dash">-</span>
                  <div className="form-group">
                    <select
                      name="max_price_input"
                      className="form-control"
                      value={priceInputs.max_price}
                      onChange={handleMaxPriceInputChange}
                    >
                      <option value="">No Max</option>
                      <option value="0">0</option>
                      <option value="500">500</option>
                      <option value="1M">1M</option>
                      <option value="1.5M">1.5M</option>
                      <option value="2M">2M</option>
                      <option value="3M">3M</option>
                    </select>
                  </div>
                </div>
                <div className="filter-field__options">
                  {priceOptions?.map((option) => (
                    <button
                      key={option}
                      className={`btn btn-sm btn-grey ${
                        selectedPriceOption === option ? "active" : ""
                      }`}
                      onClick={() => handlePriceTagChange(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="bed-bath-parking-group">
              <div className="filter-field">
                <label>Bedrooms</label>
                <div className="filter-field__options">
                  {bedBathOptions?.map((option) => (
                    <button
                      key={option.label}
                      className={`btn btn-sm btn-grey ${
                        filterState?.bedrooms === option.value ? "active" : ""
                      }`}
                      onClick={() =>
                        updateFilterState({ bedrooms: option.value })
                      }
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="filter-field">
                <label>Bathrooms</label>
                <div className="filter-field__options">
                  {bedBathOptions?.map((option) => (
                    <button
                      key={`bath-${option.label}`}
                      className={`btn btn-sm btn-grey ${
                        filterState?.bathrooms === option.value ? "active" : ""
                      }`}
                      onClick={() =>
                        updateFilterState({ bathrooms: option.value })
                      }
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-light border-0 me-2" onClick={cancelFilter}>
                Cancel
              </button>
              <button className="btn btn-primary text-white border-0" onClick={submit}>
                Submit
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown
          className="dropdown dropdown__sort-by mr-3"
          onToggle={setSortByDropdown}
          show={showSortByDropdown}
        >
          <Dropdown.Toggle
            id="sortDropdownMenu"
            className="btn btn-md filter-sort-btn"
            onClick={() => setSortByDropdown(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33333 15H6.66667C7.125 15 7.5 14.625 7.5 14.1667C7.5 13.7083 7.125 13.3333 6.66667 13.3333H3.33333C2.875 13.3333 2.5 13.7083 2.5 14.1667C2.5 14.625 2.875 15 3.33333 15ZM2.5 5.83333C2.5 6.29167 2.875 6.66667 3.33333 6.66667H16.6667C17.125 6.66667 17.5 6.29167 17.5 5.83333C17.5 5.375 17.125 5 16.6667 5H3.33333C2.875 5 2.5 5.375 2.5 5.83333ZM3.33333 10.8333H11.6667C12.125 10.8333 12.5 10.4583 12.5 10C12.5 9.54167 12.125 9.16667 11.6667 9.16667H3.33333C2.875 9.16667 2.5 9.54167 2.5 10C2.5 10.4583 2.875 10.8333 3.33333 10.8333Z"
                fill="#0061DF"
              />
            </svg>
            <span>Sort By</span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            <div className="filter-field__options sort-by-options">
              {sortByOptions?.map((option) => {
                return (
                  <button
                    className={`btn btn-sm btn-grey ${
                      selectedSortByOption === option ? "active" : ""
                    }`}
                    onClick={() => handleSortFilterChange(option)}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ListFilter;
