/** @format */

import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection.component';
import Agent from '../../components/Agent/Agent.component';
import CardContainer from '../../components/CardContainer/CardContainer.component';
import Info from '../../components/Info/Info.compoenent';
import Data1 from '../../data1.json';
import Data2 from '../../data2.json';
import Reimaged from '../../components/Reimaged/Reimaged.component';
import InfoHome from '../../components/Info-Home/InfoHome.compoenet';
import { Link } from 'react-router-dom';
import Image1 from './Images/image1.jpg';
import Image2 from './Images/image2.jpg';
import Image3 from './Images/image3.jpg';
import { MdVerified } from 'react-icons/md';
import { LinkButton } from '../../components/Reimaged/Reimaged.style'
export default function Home() {
    const content1 = (
        <div className="Content">
            <h1>At houseChat, we have reimagined Real Estate without agents</h1>
            <p>We create many ways to Buy, Sell & Invest using technology.</p>
            <div className="navigation">
                <LinkButton to="/buy-sell">Learn more Ways to Buy and Sell</LinkButton>
            </div>
        </div>
    );
    const content2 = (
        <div className="Content">
            <h1>
                Alternate to traditional
            </h1>
            <h1>Real Estate agent job</h1>
            <h1 style={{ fontStyle: 'italic' }}>WITHOUT BECOMING AN AGENT</h1>
            <div className="navigation">
                <button>Become a HouseChat assoicate</button>
            </div>
        </div>
    );
    const content3 = (
        <div className="Content">
            <h1>What is included in our Selling Package.</h1>
            <div className="list">
                <div className="list-content">
                    <MdVerified
                        size={22}
                        color="#261c1d"
                    />
                    <p> A dedicated HouseChat associate & support team.</p>
                </div>
                <div className="list-content">
                    <MdVerified
                        size={22}
                        color="#261c1d"
                    />
                    <p>Photography, floor plans & advert</p>
                </div>
                <div className="list-content">
                    <MdVerified
                        size={22}
                        color="#261c1d"
                    />
                    <p>Technology platform and iBuying option</p>
                </div>
                <div className="list-content">
                    <MdVerified
                        size={22}
                        color="#261c1d"
                    />
                    <p>Accurate home valuation software</p>
                </div>
                <div className="list-content">
                    <MdVerified
                        size={22}
                        color="#261c1d"
                    />
                    <p> Automated appointments and safe lockbox systems</p>
                </div>
            </div>
        </div>
    );
    return (
        <>
            <HeroSection />
            <Reimaged
                content={content1}
                Image={Image1}
                reverse={'imagearea contentarea'}
                imageStyle={'7 / span 6'}
                contentStyle={'1 /span 5'}
            />
            <Agent />
            <Reimaged
                content={content2}
                Image={Image2}
                reverse={'contentarea imagearea'}
                contentStyle={'8 / span 5'}
                imageStyle={'span 6'}
            />
            {/* <CardContainer Heading={'Why Sell Your House With TeamAlliance?'} data={Data1} />
            <CardContainer Heading={'Benefits of buying with us.'} data={Data2} />
            <Info /> */}
            <InfoHome />
            <Reimaged
                content={content3}
                Image={Image3}
                reverse={'imagearea contentarea'}
                imageStyle={'7 / span 6'}
                contentStyle={'1 /span 5'}
            />
        </>
    );
}
