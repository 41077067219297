/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  background: #fafafc;
  .main {
    margin-top: 4rem;
    /* padding: 2rem 3rem; */
    width: min(90rem,95%);
    margin:0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    img {
      width: 100%;
      height: 100%;
    }
    .imageContainr {
      flex: 1;
    }
    .content {
      flex: 1;
      p {
        color: #474747;
        font-family: DM Sans;
        font-size: clamp(0.75rem , 1vw,1rem);
        font-weight: 400;
        width: min(35rem,95%);
      }
      h1 {
        color: #000;
        font-family: DM Sans;
        font-size:clamp(1.5rem, 2vw, 3.5rem);
        font-weight: 400;
      }
      button {
        border-radius: 0.9375rem;
        background: #261c1d;
        box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
        padding: 1rem 2rem;
        margin-top: 1rem;
        color: white;
      }
    }
    @media screen and (max-width: 1472px) {
      .content {
        /* h1 {
          font-size: 2rem;
        } */
      }
    }
    @media screen and (max-width: 1078px) {
      flex-direction: column;
      text-align: center;
      p,
      h1 {
        text-align: left;
      }
    }
    @media screen and (max-width: 915px) {
      padding: 1rem;
    }
    @media screen and (max-width: 500px) {
      .content {
        /* h1 {
          font-size: 1.5rem;
        } */
      }
    }
    @media screen and (max-width: 375px) {
      .content {
        /* h1 {
          font-size: 1.2rem;
        } */
      }
    }
  }
`;
