/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-areas: ${(props) => `"${props.area}"`};
 /* grid-template-columns: repeat(auto-fit,30rem); */
  padding: 0 4rem;
  grid-template-columns: repeat(12, 1fr);
  justify-content: center;
  margin-top: 3.5rem;
  position: relative;
  min-height: 30rem;
  .imageContainer {
    grid-area: imagearea;
    grid-column: ${(props) => `${props.Imagestyle}`};
    height: 100%;
    margin: auto 0;
    img {
      border-radius: 15px;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 1116px) {
    gap: 2rem;
  }
  @media screen and (max-width: 893px) {
    padding:2rem 0;
    margin:0;
    grid-template-columns: 1fr;
    grid-template-areas:
      'imagearea'
      'uppersection';
    .imageContainer {
      grid-column: 1;
      margin: 0 auto;
    }
  }
`;

export const UpperSection = styled.div`
  grid-area: uppersection;
  grid-column: ${(props) => `${props.Contentstyle}`};
  margin: auto;
  h1 {
    text-align: left;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
  }
  p {
    text-align: left;
    font-size: clamp(0.75rem, 1vw, 1.5rem);
    width: min(42ch,95%);
  }
  button {
    margin-top: 1rem;
    border-radius: 0.9375rem;
    background: #261c1d;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    padding: 1rem 3rem;
    color: white;
  }
  .ending {
    color: #000;
    font-family: DM Sans;
    font-size: 1.25rem;
    font-weight: 700;
  }
  .Content {
    margin: auto;
    .list {
      display: grid;
      grid-template-columns: 20px auto;
      gap: 1rem;
    }
  }
  @media screen and (max-width: 893px) {
    grid-column: 1;
    margin:0;
    &::before {
      left: -6%;
    }
  }
  @media screen and (max-width: 893px) {
    &::before {
      left: -10%;
    }
  }
`;
