import NoImgAvailable from "./images/no-image-available.png";
import {
  priceFormat,
  getFirstPhoto,
  getPropertyAddress,
  getGarage,
  dayDiff,
} from "../../utils/property";
import "./style.css";

const PropertyCard = ({ propertyDetails, type }) => {
  const firstPhotoUrl = getFirstPhoto(propertyDetails) || NoImgAvailable;
  const formattedPrice = priceFormat(
    type === "sold"
      ? propertyDetails?.property_details?.["Lp_dol"]
      : propertyDetails?.list_price
  );
  const address = getPropertyAddress(propertyDetails);
  const garage = getGarage(propertyDetails);
  const bedrooms =
    (propertyDetails?.bedrooms || 0) -
    (+propertyDetails?.additional_bathroom || 0);

  return (
    <div className="property-card">
      <section className="property-card__header position-relative">
        <div className="top-label top-label--left">
          {propertyDetails?.property_type}
        </div>
        {/* <div className="top-label top-label--right">
          <button className="fav-btn">
            <span>
            <FontAwesomeIcon icon={faHeart}/>
            </span>
          </button>
        </div> */}
        <div
          id="property-card-img1972967"
          data-ride="carousel"
          data-interval="false"
          className="carousel"
        >
          <img src={firstPhotoUrl} alt="Property" />
        </div>
      </section>
      <section className="property-card__body">
        <div className="main-info">
          <div className="address-price-sec">
            <h3 className="price">
              $
              {type === "sold"
                ? priceFormat(propertyDetails?.sold_price)
                : formattedPrice}
            </h3>
            <p className="address mb-0">{address}</p>
          </div>
          <button className="eye-circle-btn">
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.91429 0.5C11.2785 0.5 13.5459 1.43918 15.2176 3.11093C16.8894 4.78269 17.8286 7.05007 17.8286 9.41429C17.8286 11.4928 17.1116 13.4133 15.9178 14.9356C15.7918 15.0962 15.8004 15.3266 15.9447 15.471C16.0182 15.5444 16.1178 15.5857 16.2217 15.5857H16.6011C16.948 15.5857 17.2806 15.7235 17.5259 15.9688L22.9714 21.4143C23.5395 21.9824 23.5395 22.9034 22.9714 23.4714C22.4034 24.0395 21.4824 24.0395 20.9143 23.4714L15.4688 18.0259C15.2235 17.7806 15.0857 17.448 15.0857 17.1011V16.7215C15.0857 16.6177 15.0445 16.5182 14.9711 16.4448C14.8268 16.3005 14.5962 16.2921 14.4357 16.4183C12.8641 17.6536 10.9202 18.3284 8.91429 18.3286C6.55007 18.3286 4.28269 17.3894 2.61093 15.7176C0.939181 14.0459 0 11.7785 0 9.41429C0 7.05007 0.939181 4.78269 2.61093 3.11093C4.28269 1.43918 6.55007 0.5 8.91429 0.5ZM8.91429 3.24286C5.48571 3.24286 2.74286 5.98571 2.74286 9.41429C2.74286 12.8429 5.48571 15.5857 8.91429 15.5857C12.3429 15.5857 15.0857 12.8429 15.0857 9.41429C15.0857 5.98571 12.3429 3.24286 8.91429 3.24286Z"
                fill="#0061DF"
              />
              <path
                d="M8.91429 0.5C11.2785 0.5 13.5459 1.43918 15.2176 3.11093C16.8894 4.78269 17.8286 7.05007 17.8286 9.41429C17.8286 11.4928 17.1116 13.4133 15.9178 14.9356C15.7918 15.0962 15.8004 15.3266 15.9447 15.471C16.0182 15.5444 16.1178 15.5857 16.2217 15.5857H16.6011C16.948 15.5857 17.2806 15.7235 17.5259 15.9688L22.9714 21.4143C23.5395 21.9824 23.5395 22.9034 22.9714 23.4714C22.4034 24.0395 21.4824 24.0395 20.9143 23.4714L15.4688 18.0259C15.2235 17.7806 15.0857 17.448 15.0857 17.1011V16.7215C15.0857 16.6177 15.0445 16.5182 14.9711 16.4448C14.8268 16.3005 14.5962 16.2921 14.4357 16.4183C12.8641 17.6536 10.9202 18.3284 8.91429 18.3286C6.55007 18.3286 4.28269 17.3894 2.61093 15.7176C0.939181 14.0459 0 11.7785 0 9.41429C0 7.05007 0.939181 4.78269 2.61093 3.11093C4.28269 1.43918 6.55007 0.5 8.91429 0.5ZM8.91429 3.24286C5.48571 3.24286 2.74286 5.98571 2.74286 9.41429C2.74286 12.8429 5.48571 15.5857 8.91429 15.5857C12.3429 15.5857 15.0857 12.8429 15.0857 9.41429C15.0857 5.98571 12.3429 3.24286 8.91429 3.24286Z"
                stroke="#0061DF"
              />
            </svg>
          </button>
        </div>

        {type === "sold" && (
          <div class="sold-info">
            <div class="sold-info-item">
              <label>Listed Price</label>
              <p>${formattedPrice}</p>
            </div>
            <div class="sold-info-item">
              <label>Sold On</label>
              <p>{propertyDetails?.sold_date?.split?.(" ")?.[0]}</p>
            </div>
            <div class="sold-info-item">
              <label>Sold In</label>
              <p>
                {dayDiff(
                  propertyDetails?.property_details?.Input_date,
                  propertyDetails?.property_details?.Unavail_dt
                )}{" "}
                Days
              </p>
            </div>
          </div>
        )}
      </section>
      <section className="property-card__footer">
        <ul className="ps-0">
          <li>
            <svg
              width={30}
              height={30}
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.75 8.75H16.75C15.0931 8.75 13.75 10.0931 13.75 11.75V14.5C13.75 16.1569 12.4069 17.5 10.75 17.5H6.75C5.09315 17.5 3.75 16.1569 3.75 14.5V7.5C3.75 6.80964 3.19036 6.25 2.5 6.25C1.80964 6.25 1.25 6.80964 1.25 7.5V23.75C1.25 24.4404 1.80964 25 2.5 25C3.19036 25 3.75 24.4404 3.75 23.75V23.125C3.75 22.0895 4.58947 21.25 5.625 21.25H24.375C25.4105 21.25 26.25 22.0895 26.25 23.125V23.75C26.25 24.4404 26.8096 25 27.5 25C28.1904 25 28.75 24.4404 28.75 23.75V13.75C28.75 12.4239 28.2232 11.1521 27.2855 10.2145C26.3478 9.27678 25.0761 8.75 23.75 8.75ZM8.75 16.25C9.74456 16.25 10.6984 15.8549 11.4016 15.1517C12.1049 14.4484 12.5 13.4946 12.5 12.5C12.5 11.5054 12.1049 10.5516 11.4016 9.84835C10.6984 9.14509 9.74456 8.75 8.75 8.75C7.75544 8.75 6.80161 9.14509 6.09835 9.84835C5.39509 10.5516 5 11.5054 5 12.5C5 13.4946 5.39509 14.4484 6.09835 15.1517C6.80161 15.8549 7.75544 16.25 8.75 16.25Z"
                fill="#0061DF"
              />
            </svg>
            <span>{bedrooms || 0} Beds</span>
          </li>
          <li>
            <svg
              width={26}
              height={26}
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.875 15.5C24.5298 15.5 24.25 15.7798 24.25 16.125V16.75C24.25 18.858 23.2073 20.7224 21.6037 21.8528C21.228 22.1176 21.0161 22.5757 21.128 23.0215L21.4379 24.2566C21.5964 24.888 21.119 25.5 20.468 25.5H20.0308C19.5719 25.5 19.1719 25.1877 19.0606 24.7425L18.8144 23.7575C18.7031 23.3123 18.3031 23 17.8442 23H8.15578C7.69691 23 7.29693 23.3123 7.18563 23.7575L6.93937 24.7425C6.82807 25.1877 6.42809 25.5 5.96922 25.5H5.53198C4.88103 25.5 4.4036 24.888 4.56205 24.2566L4.87183 23.0222C4.98381 22.576 4.77154 22.1178 4.39536 21.853C3.68775 21.3548 3.08916 20.713 2.64049 19.9664C2.05685 18.9951 1.74898 17.8831 1.75 16.75V16.125C1.75 15.7798 1.47018 15.5 1.125 15.5C0.779822 15.5 0.5 15.2202 0.5 14.875V14C0.5 13.4477 0.947715 13 1.5 13H22C22.5523 13 23 12.5523 23 12V4.25C23 3.91848 22.8683 3.60054 22.6339 3.36612C22.3995 3.1317 22.0815 3 21.75 3C21.3556 3 21.0209 3.16924 20.79 3.43548C20.5381 3.72585 20.6957 4.13914 20.9385 4.43712C21.2529 4.82283 21.4847 5.27331 21.6182 5.76168C21.7639 6.2944 21.3023 6.75 20.75 6.75H15.25C14.6977 6.75 14.237 6.29454 14.3825 5.76178C14.5529 5.13817 14.8832 4.56346 15.3483 4.09835C16.0516 3.39509 17.0054 3 18 3C18.1274 3 18.2398 2.91917 18.289 2.80173C18.8537 1.45526 20.1881 0.5 21.75 0.5C22.7446 0.5 23.6984 0.895088 24.4017 1.59835C25.1049 2.30161 25.5 3.25544 25.5 4.25V14.875C25.5 15.2202 25.2202 15.5 24.875 15.5Z"
                fill="#0061DF"
              />
            </svg>
            <span>{propertyDetails?.bathrooms || 0} Baths</span>
          </li>
          <li>
            <svg
              width={30}
              height={30}
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="garage-svg"
            >
              <path
                d="M23.75 24C23.75 24.5523 23.3023 25 22.75 25H22.25C21.6977 25 21.25 24.5523 21.25 24V14.75C21.25 14.1977 20.8023 13.75 20.25 13.75H9.75C9.19772 13.75 8.75 14.1977 8.75 14.75V24C8.75 24.5523 8.30228 25 7.75 25H7.25C6.69772 25 6.25 24.5523 6.25 24V11.8303C6.25 11.4715 6.44229 11.1401 6.75386 10.9621L14.5039 6.53351C14.8113 6.35783 15.1887 6.35783 15.4961 6.53351L23.2461 10.9621C23.5577 11.1401 23.75 11.4715 23.75 11.8303V24ZM10 16C10 15.4477 10.4477 15 11 15H19C19.5523 15 20 15.4477 20 16V16.5C20 17.0523 19.5523 17.5 19 17.5H11C10.4477 17.5 10 17.0523 10 16.5V16ZM10 19.75C10 19.1977 10.4477 18.75 11 18.75H19C19.5523 18.75 20 19.1977 20 19.75V20.25C20 20.8023 19.5523 21.25 19 21.25H11C10.4477 21.25 10 20.8023 10 20.25V19.75ZM19 22.5C19.5523 22.5 20 22.9477 20 23.5V24C20 24.5523 19.5523 25 19 25H11C10.4477 25 10 24.5523 10 24V23.5C10 22.9477 10.4477 22.5 11 22.5H19Z"
                fill="#0061DF"
              />
            </svg>
            <span>{garage} Garages</span>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PropertyCard;
