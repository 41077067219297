import { useEffect, useState } from "react";
import ListFilter from "../../components/ListFilter";
import PropertyCard from "../../components/PropertyCard";
import axios from "axios";
import PropertyCardSkeleton from "../../components/PropertyCardSkeleton";
import Paginate from "../../components/Paginate";
import NoResultFoundImg from "./images/no_result.gif";
import "./style.css";


const BrowseHomes = () => {
  const skeletonArr = [...Array(12)];
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [propertyListData, setPropertyListData] = useState();

  const [filterData, setFilterData] = useState({
    city: "",
    sale_or_lease: "Sale",
    property_type: "",
    property_style: "",
    bedrooms: "",
    bathrooms: "",
    min_price: "",
    max_price: "",
    sortBy: "timestamp_idx",
    sortDir: "desc",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPage(1);
    getProducts(1);
  }, [filterData]);

  const getProducts = async (page) => {
    setLoading(true);
    const url = new URL(
      `https://valuation.santhoshmathew.com/api/v1/properties/residential?page=${page}&perPage=12`
    );

    for (let [key, value] of Object.entries(filterData)) {
      if (value != "") {
        url.searchParams.append(key, value);
      }
    }

    try {
      const res = await axios.get(decodeURIComponent(url.toString()));
      setPropertyListData(res?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePageChange = (pageIndex) => {
    setPage(pageIndex.selected + 1);
    getProducts(pageIndex.selected + 1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-white min-vh-100 mb-5 pb-5">
      <ListFilter
        filterData={filterData}
        setFilterData={setFilterData}
      ></ListFilter>

      <div className="container">
        <div className="property-list-grid py-4">
          {propertyListData && !isLoading ? (
            <>
              {propertyListData.data.map((property) => {
                return (
                  <PropertyCard key={property?.id} propertyDetails={property} />
                );
              })}
            </>
          ) : (
            skeletonArr.map((_, index) => <PropertyCardSkeleton key={index} />)
          )}
        </div>

        {propertyListData?.data?.length > 0 && !isLoading && (
          <div className="d-flex justify-content-center pb-4">
            <Paginate
              forcePage={page}
              pageCount={propertyListData?.meta?.last_page}
              onPageChange={handlePageChange}
            />
          </div>
        )}

        {propertyListData?.data?.length === 0 && !isLoading ? (
          <div className="text-center my-5">
            <img src={NoResultFoundImg} alt="Not Found" className="img-fluid" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BrowseHomes;
