import React from "react";
import "./style.css"
const PropertyCardSkeleton = () => {
  return (
    <div className="skeleton-card border">
      <div className="img skeleton"></div>
      <div className="address skeleton"></div>
      <div className="address skeleton"></div>
      <div className="feature-grid border-bottom border-top">
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
      </div>
      <div className="price skeleton"></div>
    </div>
  );
};

export default PropertyCardSkeleton;
