import React from 'react'
import AssociateHeroSection from '../../components/AssociateHeroSection/AssociateHeroSection.component'
import ControlContainer from '../../components/ControlContainer/ControlContainer.component'
import Traditionla from '../../components/Traditional/Traditionla.component'
import TeamAlliance from '../../components/TeamAlliance/TeamAlliance.component'
import Faq from '../../components/FaqComponent/Faq.component'
export default function Associate() {
    return (
        <>
            <AssociateHeroSection />
            <ControlContainer />
            <Traditionla />
            <TeamAlliance />
            <Faq />
        </>
    )
}
