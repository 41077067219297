/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 3rem;
  /* position: relative; */
  @media screen and (max-width: 915px) {
    overflow: hidden;
    padding: 1rem;
    .dots {
      top: 1rem;
    }
  }
`;

export const Content = styled.div`
  padding: 4rem 7rem;
  border-radius: 1.25rem;
  background-color: rgba(38, 28, 29, 0.26);
  display: flex;
  justify-content: space-between;
  align-items: center;
   gap: 3rem;
   position: relative;
   width: min(80rem,95%);
  .dots {
    position: absolute;
    top:-3rem;
    left:-3rem;
  }
  .leftSection {
    /* width: 35%; */
    flex: 1;
    .heading {
      h1 {
        color: #261c1d;
        font-family: DM Sans;
        font-size: 4rem;
        font-weight: 400;
        text-transform: uppercase;
      }
      p {
        color: var(--Text-Grey, #6e6e73);
        font-family: DM Sans;
        font-size: 1.125rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .contactInfo {
      .contact {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 1rem 2rem;
        gap: 1rem;
        &:hover {
          border-radius: 0.625rem;
          background: var(--Template-1---White, #fdfdfe);
        }
      }
    }
    .socialMedia {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      div {
        width: 4rem;
        height: 4rem;
        background-color: white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .twitter {
        background-color: #261c1d;
      }
    }
  }
  .rightSection {
    /* width: 35%; */
    flex: 1;
    height: 100%;
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: #fff;
    position: relative;
    .circle {
      position: absolute;
      height: 85%;
      right: -14rem;
      bottom: 2rem;
      bottom: 0;
      z-index: -10;
    }
    .Details {
      p {
        color: #261c1d;
        font-family: DM Sans;
        font-size: 1rem;
        font-weight: 400;
        line-height: 150%; /* 1.5rem */
        letter-spacing: -0.02rem;
      }
      .contact-info {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.625rem;
        padding: 1rem 1rem;
        border: 2px solid var(--Template-1---Light-Grey, #f3f3f4);
        background: var(--Template-1---Light-Grey, #f3f3f4);
        margin-bottom: 1rem;
        margin-top: 1rem;
        gap: 1rem;
        .contact-input {
          width: 100%;
          border: none;
          outline: none;
          background: var(--Template-1---Light-Grey, #f3f3f4);
        }
      }
      .contact-textarea {
        margin-top: 1rem;
        padding: 1rem;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 0.625rem;
        background: var(--Template-1---Light-Grey, #f3f3f4);
      }
    }
    button {
      color: white;
      margin-top: 1rem;
      text-align: left;
      padding: 1rem 2rem;
      border-radius: 0.625rem;
      background: #261c1d;
    }
  }

  @media screen and (max-width: 1415px) {
    height: 100%;
    padding: 4rem 3rem;
    .rightSection {
       .circle {
        height: 75%;
        right: -6rem;
        bottom: 3rem;
      }
    }
    .leftSection {
    }
  }
  @media screen and (max-width: 934px) {
    .rightSection {
      .circle {
        height: 75%;
        right: -10rem;
        bottom: 3rem;
      }
    }
  }
  @media screen and (max-width: 943px) {
    margin-top: 5rem;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 4rem 2rem;
    .rightSection {
      margin-top: 2rem;
    }
    .leftSection {
      text-align: center;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
      }
      .socialMedia,
      .contact {
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 375px) {
    padding: 1rem;
    .leftSection {
      .contact-info {
      }
      .heading {
        h1 {
          font-size: 1.7rem;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .rightSection {
    padding: 1rem;
  }
`;
