import { usePlacesWidget } from "react-google-autocomplete";

export default function useGoogleAutocomplete({ onChange }) {
  return usePlacesWidget({
    apiKey: "AIzaSyABlrsdtJlypejWdegbPBnSPwYvInJUGT0",
    onPlaceSelected: onChange,
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "ca" },
    },
  });
}
