/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  padding: 6rem 2rem;
  text-align: center;
  width: min(95%,80rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto ;
  min-height: 100%;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
 @media screen and (max-width: 475px) {
    /* h1{
      font-size: 1.5rem;
    } */
  }
`;
export const CardJunction = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 1.5rem;
  @media screen and (max-width: 915px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
`;
