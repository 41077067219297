/** @format */

import styled from 'styled-components';

export const Container = styled.div``;

export const InfoHero = styled.div`
  height: 50vh;
  background-image: url(${(props) => props.Bgimg});
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 10rem;

  h1 {
    color: #000;
    font-family: DM Sans;
    font-size: 4rem;
    font-weight: 400;
    margin-left: 5rem;
  }
  p {
    color: #474747;
    font-family: DM Sans;
    font-size: 1.5rem;
    font-weight: 400;
    margin-left: 5rem;
  }
  @media screen and (max-width: 915px) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media screen and (max-width: 458px) {
      padding-top: 3rem;
      h1,p{
        margin-left: 2rem;
      }
  }
`;

export const Content = styled.div`
  /* height: 50vh; */
  margin: 4rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  .Card {
    text-align: left;
    height:21rem;
    width: 30rem;
    border-radius: 1.25rem;
    background: #fff;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    padding: 2rem;
    p {
      margin-bottom: 1rem;
      color: #474747;
      font-family: DM Sans;
      font-size:clamp(0.75rem , 1vw,1rem);
      font-weight: 400;
    }
    h1 {
      margin-bottom: 1rem;
      color: #000;
      font-family: DM Sans;
      font-size:clamp(1.5rem, 2vw, 3.5rem);
      font-weight: 700;
    }
  }
  @media screen and (max-width: 915px) {
    flex-direction: column;
    .Card {
      width: 75%;
       height: max-content;
    }
  }
  @media screen and (max-width: 375px) {
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    .Card {
      width: 100%;
    }
  }
`;
