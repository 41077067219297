import React from 'react'
import { Container } from './InfoHome.style'
import FlowChart from './Images/Flowchart Dark.svg'
export default function InfoHome() {
    return (
        <Container>
            <h1>HouseChat Selling Process</h1>
            <img src={FlowChart} alt="flow chart" />
        </Container>
    )
}
