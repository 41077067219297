/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  width: 30rem;
  width: calc(33.33% - 4rem);
  text-align: left;
  height: 23rem;
  background-color: white;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 10px;
  h1 {
    color: #000;
    font-family: DM Sans;
    font-size: 1.625rem;
    font-weight: 400;
    text-align: left;
  }
  p {
    color: #474747;
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: 1074px) {
    width: calc(33.33% - 1rem);
  }

  @media screen and (max-width: 915px) {
    width: 60%;
    padding: 1rem;
  }
    @media screen and (max-width: 486px) {
    width: 100%;
    height: 100%;
  }
`;
