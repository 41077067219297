/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #261c1d;
  min-height: 50vh;
  padding: 3rem;
  img {
    width: 100%;
    height: 100%;
    padding: 2rem 0;
  }
  h1 {
    /* text-align: center; */
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    color: white;
  }
`;
