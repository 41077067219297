/** @format */

import React from 'react';
import { Conatainer, Content } from './HeroSection.style';
import HeroImage from './Images/Hero-Images.jpg';
export default function HeroSection() {
    return (
        <Conatainer>
            <div className="main">
                <Content>
                    <h1>Technology Powered Real Estate</h1>
                    <h2>0% Commission. 100% Happiness</h2>
                    {/* <p>Sell homes with HouseChat. Technology based Real estate, without realtors</p> */}
                    <p style={{ fontStyle: 'italic' }}>AI. Blockchain. AVM.</p>
                    <button>Get Started</button>
                </Content>
                <div className='ImageContainer'>
                    <img src={HeroImage} alt="HeroImage" />
                </div>
            </div>
        </Conatainer>
    );
}
