/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 5rem;
  width: min(95%, 90rem);
  margin: 0 auto;
  @media screen and (max-width: 1274px) {
    padding: 2rem 4rem;
  }
  @media screen and (max-width: 910px) {
    padding: 2rem 2rem;
  }
  @media screen and (max-width: 458px) {
    margin-top: 2rem;
    padding: 0;
  }
`;

export const UperSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  .rightSection {
    padding: 1rem 4rem;
    text-align: center;
    h3 {
      color: #000;
      text-align: center;
      font-family: DM Sans;
      /* font-size: 2.93806rem; */
      font-size: clamp(1.5rem, 1.7vw, 3.5rem);
      font-weight: 700;
    }
    h1 {
      color: #000;
      font-family: DM Sans;
      /* font-size: 4.59069rem; */
      font-size: clamp(1.5rem, 2vw, 3.5rem);
      font-weight: 700;
    }
  }
  .leftSection {
    flex: 1;
    padding: 2rem;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center;
    p {
      color: #474747;
      font-family: DM Sans;
      font-size:clamp(0.75rem , 1vw,1rem);
      font-weight: 400;
      text-align: left;
      width: min(80rem, 85%);
      margin: 0 auto;
    }
    button {
      border-radius: 0.9375rem;
      background: #261c1d;
      padding: 1rem 2rem;
      box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
      color: white;
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 1418px) {
    gap: 1rem;
    flex-direction: column-reverse;
    .leftSection {
      text-align: center;
      padding: 2rem;
      padding-top: 0px;
      /* h1 {
        font-size: 2rem;
      } */
      p {
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 818px) {
    .rightSection {
      padding: 0;
      /* h1 {
        font-size: 2.5rem;
      }
      h3 {
        font-size: 2rem;
      } */
    }
  }

  @media screen and (max-width: 474px) {
    .leftSection {
      padding: 1rem;
      button {
        margin-top: 1rem;
      }
    }
    .rightSection {
      padding: 0;
      /* h3 {
        font-size: 1.6rem;
      }
      h1 {
        font-size: 1.6rem;
      } */
    }
  }
`;

export const DownSection = styled.div`
  background-color: #261c1d;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  .rightSection {
    flex: 1;
    padding: 4rem;
    text-align: center;
    p {
      color: #fff;
      font-family: DM Sans;
      font-size:clamp(0.75rem , 1vw,1rem);
      font-weight: 400;
    }
  }
  .leftSection {
    flex: 1;
    padding: 2rem;
    h1 {
      color: #fff;
      text-align: center;
      font-family: DM Sans;
      font-size:clamp(1.5rem, 2vw, 3.5rem);
      font-weight: 700;
    }
  }
  @media screen and (max-width: 1418px) {
    flex-direction: column;
    gap: 1rem;
    .rightSection,
    .leftSection {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 910px) {
    gap: 1rem;
    .rightSection,
    .leftSection {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    /* .leftSection > h1 {
      font-size: 2rem;
    } */
  }
  @media screen and (max-width: 702px) {
    flex-direction: column;
    /* .leftSection > h1 {
      font-size: 2rem;
    } */
  }
  @media screen and (max-width: 458px) {
    margin-top: 1rem;

    /* .leftSection h1 {
      font-size: 1.5rem;
    } */
  }
`;
