/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  padding: 2rem 3rem;
  width: min(90rem, 95%);
  margin: 0 auto;
  @media screen and (max-width: 769px) {
    padding: 1rem;
  }
`;
export const Content = styled.div`
  padding: 0 1rem;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
  }
  p {
    color: #474747;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(0.75rem , 1vw,1rem);
    font-weight: 400;
    width: min(30rem,95%);
    margin: 0 auto;
  }
  @media screen and (max-width: 769px) {
    padding: 0;
    /* h1 {
      font-size: 2rem;
    } */
  }
  @media screen and (max-width: 458px) {
    h1 {
      /* font-size: 1.5rem; */
      margin-bottom: 1rem;
    }
  }
`;

export const DiffConntainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: 733px) {
    flex-direction: column;
  }
`;
