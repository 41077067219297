/** @format */

import React from 'react';
import { Container, CardJunction } from './TeamAlliance.style';
import Image1 from './Images/TeamAlliance-1.png';
import Image2 from './Images/TeamAlliance-2.png';
import Image3 from './Images/TeamAlliance-3.png';
import TeamAllianceCard from './TeamAllianceCard.component';
export default function TeamAlliance() {
    return (
        <Container>
            <h1>Why become HouseChat sales associate?</h1>
            <CardJunction>
                <TeamAllianceCard
                    Timage={Image1}
                    heading={'Set your own hours.'}
                    paragraph={
                        'Sales associate opportunities can mean networking wherever you go with your own schedules. If you become a sales associate with HouseChat , there is opportunity everywhere you go.'
                    }
                />
                <TeamAllianceCard
                    Timage={Image2}
                    heading={'Get paid soon and later.'}
                    paragraph={
                        'When you get paid to sales or referring to sales using HouseChat products, you get paid soon for some of products and get paid later for bigger transactions.'
                    }
                />
                <TeamAllianceCard
                    Timage={Image3}
                    heading={'Get support at every turn.'}
                    paragraph={
                        'You get trained and supported as required. You will introduce to our promotions and products lines with virtual trainings.'
                    }
                />
            </CardJunction>
        </Container>
    );
}
