/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  width: min(85%, 80rem);
  margin: 2rem auto;
  /* min-height: 40rem; */
  .main {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, 30rem); */
    grid-template-columns: repeat(12, 1fr);
    min-height: 40rem;
    height: 100%;
    grid-template-areas: ${(props) => `"${props.area}"`};
    padding-top: 3rem;
    justify-content: center;
    margin-bottom: 5rem;
    .Image {
      grid-area: imagearea;
       grid-column: ${(props) => `${props.Imagestyle}`};
      margin: 0 auto;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
      /* aspect-ratio: 1/1; */
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        object-fit: cover;
      }
    }
    .Content {
      margin: auto;
      grid-area: contentarea;
      grid-column: ${(props) => `${props.Contentstyle}`};
      h1 {
        color: #000;
        font-family: DM Sans;
        /* font-size: 3.5rem; */
        font-size: clamp(1.5rem, 2vw, 3.5rem);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      p {
        font-size: clamp(0.75rem, 1vw, 1rem);
      }
      span {
        color: #000;
        font-family: DM Sans;
        font-size: clamp(1.5rem, 2vw, 3.5rem);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      button {
        padding: 1rem 1.5rem;
        color: white;
        border-radius: 0.9375rem;
        background: #261c1d;
        box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
      }
    }
    .navigation {
      margin-top:1.7rem;
      /* display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.5rem;

      a {
        position: relative;
        padding-bottom: 0.4rem;
        color: black;
        text-decoration: none;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: 1px solid grey;
          width: 100%;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom: 2px solid black;
          width: 100%;
          transform: scale(0);
          transform-origin: left;
          transition: all 0.7s;
        }
        &:hover::after {
          transform: scale(1);
        }
      } */
    }

    .list {
      .list-content {
        display: grid;
        grid-template-columns: 20px auto;
        gap: 1rem;
      }
    }
  }
  @media screen and (max-width: 1116px) {
    .main {
      gap: 2rem;
    }
  }
  @media screen and (max-width: 855px) {
    .main {
      height: 100%;
      grid-template-columns: 1fr;
      justify-items: center;
      grid-template-areas:
        'imagearea'
        'contentarea';
      .Image {
        height: 100%;
         grid-column: 1;
      }
      .Content {
         grid-column: 1;
        li {
          font-size: 1rem;
        }
      }
    }
  }
`;

export const LinkButton = styled(Link)`
  padding: 1rem 1.5rem;
  color: white;
  border-radius: 0.9375rem;
  background: #261c1d;
  box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
`;
