/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  width: min(95%, 90rem);
  margin: 6rem auto;
  .Heading{
     color: white;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
    background-color: grey;
    padding: 2rem;
  }
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
  }
  p {
    color: #474747;
    text-align: center;
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: 400;
  }
  /* .content {
    border-left: 2px dashed black;
  } */
  @media screen and (max-width:855px) {
     /* h1{
       font-size: 1.5rem;
     } */
  }
`;
