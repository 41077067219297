import React from 'react'
import { Container } from './TeamAllianceCard.style'
export default function TeamAllianceCard({ Timage, heading, paragraph }) {
    return (
        <Container>
            <img src={Timage} alt="" />
            <h1>{heading}</h1>
            <p>{paragraph}</p>
        </Container>
    )
}
