import React from 'react'
import { Conatainer, Content } from './BenefitsHeroSection.style'
import HeroImage from './Images/BenefitsImage.jpg'
export default function BenefitsHeroSection() {
    return (
        // <Conatainer>
        //     <Content>
        //         <h1>Become An Associate</h1>
        //         <p>Join us today.
        //             The right place for you.</p>
        //     </Content>
        // </Conatainer>
        <Conatainer>
            <div className="main">
                <Content>
                    <h1>Become An Associate</h1>
                    <p>Join us today.
                        The right place for you.</p>
                </Content>
                <div className='ImageContainer'>
                    <img src={HeroImage} alt="HeroImage" />
                </div>
            </div>
        </Conatainer>
    )
}
