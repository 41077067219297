/** @format */

import React from 'react';
import { Container, Content, FooterLink } from './Footer.style';
import Facebook from '../../assets/Images/facebook-2.svg';
import Twitter from '../../assets/Images/twitter-2.png';
import Instagram from '../../assets/Images/instagram-2.png';
export default function Footer() {
    return (
        <Container>
            <div className="main">
                <div className="left-side">
                    <h1>HouseChat</h1>
                    <p> 191 Superior Blvd, Mississauga,<br /> ON L5T 2L6, Canada</p>
                    <div className="navigation">
                        <div className="navigation_container">
                            <img
                                src={Facebook}
                                alt="facebook"
                            />
                        </div>
                        <div className="navigation_container">
                            <img
                                src={Twitter}
                                alt="Twitter"
                            />
                        </div>
                        <div className="navigation_container">
                            <img
                                src={Instagram}
                                alt="Instagram"
                            />
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <div className="footer-link Benefits">
                        <h1>Benefits</h1>
                        <FooterLink>Referral</FooterLink>
                        <FooterLink>Training </FooterLink>
                        <FooterLink>Support</FooterLink>
                        <FooterLink>Convenient Schedule </FooterLink>
                    </div>
                    <div className="footer-link Services">
                        <h1>Services</h1>
                        <FooterLink>Tools</FooterLink>
                        <FooterLink>Community</FooterLink>
                        <FooterLink>Advertising</FooterLink>
                    </div>
                    <div className="footer-link Policies">
                        <h1>Policies</h1>
                        <FooterLink>Privacy Policy </FooterLink>
                        <FooterLink>Terms and Condition</FooterLink>
                    </div>
                    <div className="footer-link About-us">
                        <h1>About Us</h1>
                        <FooterLink>Home Value</FooterLink>
                        <FooterLink>Associate Benefit</FooterLink>
                        <FooterLink>Featured Properties</FooterLink>
                        <FooterLink>Become an Associate </FooterLink>
                    </div>
                </div>
            </div>
            <Content>
                <p>
                    HouseChat is a private sale franchise network and marketplace. Each
                    HouseChat franchise is independently owned and operated
                    (collectively “Us” or “We”). We represent neither the buyer nor the
                    seller and we are not licensed to trade in real estate. We neither
                    warranty nor make any representations as to the outcome of a property
                    sale. Our approach includes services which are performed for our users
                    by third party service providers. Such services are not performed or
                    provided by us. The trademarks MLS®, Multiple Listing Service® are
                    owned by The Canadian Real Estate Association (CREA) and identify the
                    quality of services provided by real estate professionals who are
                    members of CREA. Licensed real estate services advertised over this
                    web site are delivered by PG Direct Realty Ltd who is a member of
                    CREA. Not all services depicted are available in all areas. © 2023
                    HouseChat., All Rights Reserved.
                </p>

                <p className="copy_right">Copyright ©2023 HouseChat.com</p>
            </Content>
        </Container>
    );
}
