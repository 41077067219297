/** @format */

import styled from 'styled-components';

export const Conatainer = styled.div`
  background-color: #261c1d;
  .main {
    width: min(95%, 80rem);
    min-height: 95vh;
    max-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:'content imageContainer' ;
    /* height:100%; */
    padding: 4rem;
    gap: 2rem;
    margin: 0 auto;
    .ImageContainer {
      grid-area: imageContainer;
      height: auto;
      max-width: 100%;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }
  }
    @media screen and (max-width:1071px) {
      .main{
          /* padding: 2rem; */
          gap: 2rem;
      }
  }
  @media screen and (max-width:910px) {
     .main{
      padding: 0;
      height: 100%;
       grid-template-columns: 1fr;
       grid-template-areas:
       'imageContainer'
       'content'
       ;
     }
  }
`;

export const Content = styled.div`
grid-area: content;
  border-radius: 1.5625rem;
  /* background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(7.5px); */
  /* padding: 3rem; */
  text-align:left;
  margin: auto 1rem;
  h1 {
    color:white;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 2.5vw, 4rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
  h2{
    color:white;
    font-family: DM Sans;
    font-size:clamp(1.5rem, 1.5vw, 4rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }
  p {
    color:white;
    font-family: DM Sans;
    /* font-style: 15px; */
    font-size:clamp(1rem, 1.5vw, 4rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-style: italic;
    margin-bottom: 1rem;
  }

  button {
    padding: 1rem;
    color: #261c1d;
    border-radius: 0.9375rem;
    background: white;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
  }

  @media screen and (max-width:1071px) {
      padding: 2rem;
  }
    @media screen and (max-width:910px) {
    padding: 1rem;
     h1{
      font-size: 1.5rem;
     }
  }
`;
