/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: clamp(1.5rem, 2vw, 3.5rem);
    font-weight: 400;
    margin-bottom: 1rem;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    filter: grayscale(100%);
  }

  @media screen and (max-width: 733px) {
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 896px) {
    /* h1 {
      font-size: 2rem;
    } */
  }
`;
