import React, { memo } from "react";
import Bg2Img from "../images/stepper-bg-2.png";
import useGoogleAutocomplete from "../../../hooks/useGoogleAutocomplete";

const SecondValuationForm = ({
  formFields,
  updateFormField,
  goBack,
  submit,
}) => {
  const { ref: autocompleteRef } = useGoogleAutocomplete({
    onChange: (place) => {
      console.log(place);

      let streetNumber = place?.address_components?.find(
        (el) => el?.types?.[0] == "street_number"
      );

      let streetName = place?.address_components?.find(
        (el) => el?.types?.[0] == "route"
      );

      let city = place?.address_components?.find(
        (el) => el?.types?.[0] == "locality"
      );

      let zip = place?.address_components?.find(
        (el) =>
          el?.types?.[0] == "postal_code" || el?.types?.[1] == "postal_code"
      )?.long_name;

      updateFormField("address", place?.formatted_address || "");
      updateFormField("address_short", streetNumber?.short_name + ' ' + streetName?.short_name);
      updateFormField("latitude", place?.geometry?.location?.lat?.() || "");
      updateFormField("longitude", place?.geometry?.location?.lng?.() || "");
      updateFormField("city", city?.long_name || city || "");
      updateFormField("zip_code", zip || "");
    },
  });

  return (
    <div className="step-layout">
      <section
        className="step-layout-left "
        style={{ background: `url(${Bg2Img}) rgba(0, 0, 0, 0.7)` }}
      >
        <div className="step-layout-container">
          <h2 className="text-white intro-title">
            We are using artificial intelligence with AVM (Automated Valuation
            Method)
            <br className="d-none d-sm-inline" />
            to find the home value
          </h2>
          <div className="text-center text-sm-left">
            <button className="btn btn-primary" onClick={goBack}>
              <i className="fa-solid fa-circle-arrow-left mr-2"></i>BACK
            </button>
          </div>
        </div>
      </section>
      <section className="step-layout-right">
        <div className="step-layout-container pb-2">
          <h2 className="text-primary title">
            These questions help us provide a better home value.
          </h2>
          <form className="text-center home-details-form" onSubmit={submit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                ref={autocompleteRef}
              />
            </div>
            <div className="form-group list-select">
              <select
                className="form-control selectpicker"
                value={formFields?.type}
                onChange={(e) => updateFormField("type", e.target.value)}
                required
              >
                <option value="" disabled={true}>
                  Type of Home
                </option>
                <option value="Detached">Detached Home</option>
                <option value="Semi-Detached">Semi-Detached</option>
                <option value="Att/Row/Twnhouse">Att/Row/Townhouse</option>
                <option value="Condo Townhouse">Condo Townhouse</option>
                <option value="Condo Apt">Condo Apartment</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="form-group list-select">
              <select
                className="form-control selectpicker"
                value={formFields?.home_style}
                onChange={(e) => updateFormField("home_style", e.target.value)}
              >
                <option value="" disabled={true}>
                  Style of home
                </option>
                <option value="2-story">2 Story</option>
                <option value="3-story">3 Story</option>
                <option value="Bungalow">Bungalow</option>
                <option value="Back split">Back split</option>
                <option value="Side split">Side split</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="form-group grid-select">
              <select
                className="form-control selectpicker"
                value={formFields?.bedrooms}
                onChange={(e) => updateFormField("bedrooms", e.target.value)}
                required
              >
                <option value="" disabled={true}>
                  Bedroom (Above ground)
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6+">6+</option>
              </select>
            </div>
            <div
              className="form-group grid-select"
              value={formFields?.bathrooms}
              onChange={(e) => updateFormField("bathrooms", e.target.value)}
              required
            >
              <select className="form-control selectpicker">
                <option value="" disabled={true}>
                  Bathroom
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6+">6+</option>
              </select>
            </div>

            <div className="form-group list-select">
              <select
                className="form-control selectpicker"
                value={formFields?.square_foot}
                onChange={(e) => updateFormField("square_foot", e.target.value)}
              >
                <option value="" disabled={true}>
                  Square footage (Above ground)
                </option>
                <option value="500-1000">500-1000</option>
                <option value="1000-2000">1000-2000</option>
                <option value="2000-3000">2000-3000</option>
                <option value="3000-4000">3000-4000</option>
                <option value="4000+">4000+</option>
              </select>
            </div>

            <div className="form-group grid-select">
              <select
                className="form-control selectpicker"
                value={formFields?.garage}
                onChange={(e) => updateFormField("garage", e.target.value)}
                required
              >
                <option value="" disabled={true}>
                  Garage
                </option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3+">3+</option>
              </select>
            </div>

            <div className="form-group grid-select">
              <select
                className="form-control selectpicker"
                value={formFields?.basement}
                onChange={(e) => updateFormField("basement", e.target.value)}
                required
              >
                <option value="" disabled={true}>
                  Basement Type
                </option>
                <option value="Finished">Finished</option>
                <option value="Unfinished">Unfinished</option>
              </select>
            </div>

            <div className="text-lg-end submit-btn-wrapper">
              <button className="btn btn-primary btn-lg submit-btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default memo(SecondValuationForm);
