/** @format */

import React from 'react';
import { Conatainer, Content, CardJunction } from './BuildingIncome.style';
import IncomeCard from './IncomeCad.component';
import Image1 from './Images/inComeCard-1.png';
import Image2 from './Images/inComeCard-2.png';
import Image3 from './Images/inComeCard-3.png';
import Image4 from './Images/inComeCard-4.png';
export default function BuildIncome() {
    return (
        <Conatainer>
            <Content>
                <h1>Four Ways to Build Income</h1>
                <p>
                    HouseChat offers one of the industry’s most robust compensation
                    plans on a diversified product portfolio. As you advance through the
                    ranks and build your business, you can earn more money while helping
                    more people.
                </p>
            </Content>
            <CardJunction>
                <IncomeCard
                    image={Image1}
                    heading={'Expansion Income'}
                    paragraph={
                        'Financial entrepreneurs like you are free to expand their business anywhere in the US or Canada – the HouseChat platform supports your efforts and help you support your organization.'
                    }
                />
                <IncomeCard
                    image={Image2}
                    heading={'Bonus Income'}
                    paragraph={
                        'Qualified agents earn bonus income, which grows based on the entire company’s production, that can multiply and compound your earning potential.'
                    }
                />
                <IncomeCard
                    image={Image3}
                    heading={'Personal Income'}
                    paragraph={
                        'Qualified agents earn bonus income, which grows based on the entire company’s production, that can multiply and compound your earning potential.'
                    }
                />
                <IncomeCard
                    image={Image4}
                    heading={'Agency Income'}
                    paragraph={
                        'As your business grows, you can build a team by mentoring and developing newly licensed agents. As they write new business, you earn a percentage of their sales through overrides.'
                    }
                />
            </CardJunction>
        </Conatainer>
    );
}
