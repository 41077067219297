/** @format */

import React from 'react';
import { Container } from './Traditional.style';
import Traditional from './Images/Traditinal-image.jpg';
export default function Traditionla() {
    return (
        <Container>
            <div className="main">
                <img
                    src={Traditional}
                    alt="Traditional"
                />
                <div className="content">
                    <h1>An alternate to Traditional</h1>
                    <h1>Real estate agent jobs.</h1>
                    <p>
                        Real estate with HouseChat offers a flexible earning opportunity.
                        It’s a great alternative to full-time jobs, part-time driver real
                        estate associate, or other part-time marketer, reels, youtuber or
                        seasonal employment with real estate sales. Or maybe you’re already
                        a network or database and want to make your income by using the
                        HouseChat platform.
                    </p>
                    <p>
                        {' '}
                        HouseChat offers an alternative to traditional part-time jobs in
                        major cities in Canada, including Toronto, Ottawa, Winnipeg and
                        Calgary and many cities of all sizes across the country.
                    </p>
                </div>
            </div>
        </Container>
    );
}
